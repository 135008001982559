/* custom */
//$main: #c50008;
$main: #DD0105;
$dark: #333333;
$light:#ffffff;
$font-icon:  "Font Awesome 5 Free";
$main_border:1px solid #d9d9d9;



//@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";


@import "animate.scss";
@import "fontawesome-all.scss";

@mixin pos-transition($number) {
	-webkit-transition: all $number ease-in;
	-moz-transition: all $number ease-in;
	-ms-transition: all $number ease-in;
	-o-transition: all $number ease-in;
	transition: all $number ease-in; 
}
@mixin pos-transform($number) {
	-moz-transform: scale3d($number,$number,$number);
	-webkit-transform: scale3d($number,$number,$number);
	-o-transform: scale3d($number,$number,$number);
	-ms-transform: scale3d($number,$number,$number);
	transform: scale3d($number,$number,$number);
}
@function get1024-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (1024 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}
@function get767-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (767 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw; 
}
@function get480-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (480 * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}
@mixin placeholder {
  &::-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
  &:-ms-input-placeholder { @content }
}
@font-face {
  font-family: 'abcicon';
  src:  url('fonts/abcicon.eot?37yrw4');
  src:  url('fonts/abcicon.eot?37yrw4#iefix') format('embedded-opentype'),
    url('fonts/abcicon.ttf?37yrw4') format('truetype'),
    url('fonts/abcicon.woff?37yrw4') format('woff'),
    url('fonts/abcicon.svg?37yrw4#abcicon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url("fonts/webfonts/fa-light-300.eot");
  src: url("fonts/webfonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("fonts/webfonts/fa-light-300.woff2") format("woff2"), url("fonts/webfonts/fa-light-300.woff") format("woff"), url("fonts/webfonts/fa-light-300.ttf") format("truetype"), url("fonts/webfonts/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'abcicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-laptop:before {
  content: "\e001";
}
.icon-desktop2:before {
  content: "\e002";
}
.icon-tablet:before {
  content: "\e003";
}
.icon-phone:before {
  content: "\e004";
}
.icon-camera:before {
  content: "\e012";
}
.icon-printer:before {
  content: "\e013";
}
.icon-wallet:before {
  content: "\e016";
}
.icon-basket:before {
  content: "\e027";
}
.icon-tools-2:before {
  content: "\e034";
}
.icon-bike:before {
  content: "\e042";
}
.icon-facebook:before {
  content: "\e05d";
}
.icon-twitter2:before {
  content: "\e05e";
}
.icon-googleplus:before {
  content: "\e05f";
}
.icon-rss:before {
  content: "\e060";
}
.icon-tumblr:before {
  content: "\e061";
}
.icon-linkedin:before {
  content: "\e062";
}
.icon-dribbble2:before {
  content: "\e063";
}
.icon-quote:before {
  content: "\e900";
}
.icon-quote2:before {
  content: "\e901";
}
.icon-phone2:before {
  content: "\e902";
}
.icon-window:before {
  content: "\e903";
}
.icon-monitor:before {
  content: "\e904";
}
.icon-ipod:before {
  content: "\e905";
}
.icon-camera2:before {
  content: "\e906";
}
.icon-film:before {
  content: "\e907";
}
.icon-film2:before {
  content: "\e908";
}
.icon-drink:before {
  content: "\e909";
}
.icon-cog:before {
  content: "\e90a";
}
.icon-time:before {
  content: "\e90b";
}
.icon-chart:before {
  content: "\e90c";
}
.icon-gamepad:before {
  content: "\e90d";
}
.icon-mouse:before {
  content: "\e90e";
}
.icon-lamp:before {
  content: "\e90f";
}
.icon-lamp2:before {
  content: "\e910";
}
.icon-comments:before {
  content: "\e911";
}
.icon-justice:before {
  content: "\e912";
}
.icon-stats:before {
  content: "\e913";
}
.icon-pig:before {
  content: "\e914";
}
.icon-heart2:before {
  content: "\e915";
}
.icon-shipping:before {
  content: "\e916";
}
.icon-heart3:before {
  content: "\e917";
}
.icon-star2:before {
  content: "\e918";
}
.icon-user2:before {
  content: "\e919";
}
.icon-search2:before {
  content: "\e91a";
}
.icon-settings:before {
  content: "\e91b";
}
.icon-camera3:before {
  content: "\e91c";
}
.icon-lock:before {
  content: "\e91d";
}
.icon-display:before {
  content: "\e91e";
}
.icon-location3:before {
  content: "\e91f";
}
.icon-eye2:before {
  content: "\e920";
}
.icon-bubble:before {
  content: "\e921";
}
.icon-phone3:before {
  content: "\e922";
}
.icon-mail:before {
  content: "\e923";
}
.icon-clock2:before {
  content: "\e924";
}
.icon-paperplane:before {
  content: "\e925";
}
.icon-banknote:before {
  content: "\e926";
}
.icon-shop:before {
  content: "\e927";
}
.icon-calendar:before {
  content: "\e928";
}
.icon-wallet2:before {
  content: "\e929";
}
.icon-truck2:before {
  content: "\e92a";
}
.icon-world:before {
  content: "\e92b";
}
.icon-loop:before {
  content: "\e92c";
}
.icon-loop-alt2:before {
  content: "\e92d";
}
.icon-loop-alt3:before {
  content: "\e92e";
}
.icon-lock-stroke:before {
  content: "\e92f";
}
.icon-search3:before {
  content: "\e930";
}
.icon-envelope2:before {
  content: "\e931";
}
.icon-compose:before {
  content: "\e932";
}
.icon-envelope3:before {
  content: "\e933";
}
.icon-cart2:before {
  content: "\e934";
}
.icon-monitor2:before {
  content: "\e935";
}
.icon-headphones:before {
  content: "\e936";
}
.icon-gift:before {
  content: "\e937";
}
.icon-book:before {
  content: "\e938";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-lifebuoy:before {
  content: "\e941";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-location:before {
  content: "\e947";
}
.icon-location2:before {
  content: "\e948";
}
.icon-map2:before {
  content: "\e94c";
}
.icon-clock:before {
  content: "\e94e";
}
.icon-user:before {
  content: "\e971";
}
.icon-stats-bars:before {
  content: "\e99c";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-pause:before {
  content: "\ea16";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-vimeo:before {
  content: "\eaa0";
}
.icon-flickr:before {
  content: "\eaa3";
}
.icon-dribbble:before {
  content: "\eaa7";
}
.icon-behance:before {
  content: "\eaa8";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-pinterest:before {
  content: "\ead1";
}
.icon-pinterest2:before {
  content: "\ead2";
}
.icon-truck3:before {
  content: "\e939";
}
.icon-star3:before {
  content: "\e93b";
}
.icon-lock-closed:before {
  content: "\e93c";
}
.icon-arrow-sync:before {
  content: "\e93d";
}
.icon-arrow-shuffle:before {
  content: "\e93e";
}
.icon-arrow-repeat:before {
  content: "\e940";
}
.icon-home-outline:before {
  content: "\e942";
}
.icon-location-outline:before {
  content: "\e943";
}
.icon-star-outline:before {
  content: "\e945";
}
.icon-mail2:before {
  content: "\e946";
}
.icon-heart-outline:before {
  content: "\e949";
}
.icon-lock-closed-outline:before {
  content: "\e94a";
}
.icon-eye-outline:before {
  content: "\e94b";
}
.icon-camera-outline:before {
  content: "\e94d";
}
.icon-support:before {
  content: "\e94f";
}
.icon-tag:before {
  content: "\e950";
}
.icon-edit:before {
  content: "\e951";
}
.icon-contacts:before {
  content: "\e952";
}
.icon-credit-card3:before {
  content: "\e953";
}
.icon-coffee:before {
  content: "\e954";
}
.icon-tree:before {
  content: "\e955";
}
.icon-shopping-cart:before {
  content: "\e956";
}
.icon-card_travel:before {
  content: "\e8f8";
}
.icon-date_range:before {
  content: "\e957";
}
.icon-done_all:before {
  content: "\e877";
}
.icon-markunread:before {
  content: "\e159";
}
.icon-equalizer:before {
  content: "\e01d";
}
.icon-favorite:before {
  content: "\e87d";
}
.icon-favorite_border:before {
  content: "\e87e";
}
.icon-headset_mic:before {
  content: "\e311";
}
.icon-help_outline:before {
  content: "\e8fd";
}
.icon-lock2:before {
  content: "\e897";
}
.icon-laptop_mac:before {
  content: "\e320";
}
.icon-linked_camera:before {
  content: "\e438";
}
.icon-shopping_cart:before {
  content: "\e8cc";
}
.icon-local_mall:before {
  content: "\e54c";
}
.icon-local_shipping:before {
  content: "\e558";
}
.icon-lock_outline:before {
  content: "\e899";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-phone_in_talk:before {
  content: "\e61d";
}
.icon-visibility:before {
  content: "\e8f4";
}
.icon-repeat:before {
  content: "\e040";
}
.icon-settings_phone:before {
  content: "\e8c5";
}
.icon-sort:before {
  content: "\e164";
}
.icon-subject:before {
  content: "\e8d2";
}
.icon-verified_user:before {
  content: "\e8e8";
}
.icon-align-center:before {
  content: "\e958";
}
.icon-align-justify:before {
  content: "\e959";
}
.icon-align-left:before {
  content: "\e95a";
}
.icon-align-right:before {
  content: "\e95b";
}
.icon-aperture:before {
  content: "\e95c";
}
.icon-bar-chart:before {
  content: "\e95d";
}
.icon-bar-chart-2:before {
  content: "\e95e";
}
.icon-briefcase:before {
  content: "\e95f";
}
.icon-calendar2:before {
  content: "\e960";
}
.icon-camera4:before {
  content: "\e961";
}
.icon-credit-card4:before {
  content: "\e962";
}
.icon-database:before {
  content: "\e963";
}
.icon-edit2:before {
  content: "\e964";
}
.icon-eye3:before {
  content: "\e965";
}
.icon-facebook2:before {
  content: "\e966";
}
.icon-gift2:before {
  content: "\e967";
}
.icon-headphones2:before {
  content: "\e968";
}
.icon-heart4:before {
  content: "\e969";
}
.icon-help-circle:before {
  content: "\e96a";
}
.icon-home2:before {
  content: "\e96b";
}
.icon-instagram2:before {
  content: "\e96c";
}
.icon-life-buoy:before {
  content: "\e96d";
}
.icon-linkedin3:before {
  content: "\e96e";
}
.icon-list:before {
  content: "\e96f";
}
.icon-lock3:before {
  content: "\e970";
}
.icon-mail3:before {
  content: "\e972";
}
.icon-map:before {
  content: "\e973";
}
.icon-map-pin:before {
  content: "\e974";
}
.icon-menu:before {
  content: "\e975";
}
.icon-message-circle:before {
  content: "\e976";
}
.icon-minus:before {
  content: "\e977";
}
.icon-minus-circle:before {
  content: "\e978";
}
.icon-monitor3:before {
  content: "\e979";
}
.icon-percent:before {
  content: "\e97a";
}
.icon-phone4:before {
  content: "\e97b";
}
.icon-phone-call:before {
  content: "\e97c";
}
.icon-phone-forwarded:before {
  content: "\e97d";
}
.icon-phone-incoming:before {
  content: "\e97e";
}
.icon-phone-outgoing:before {
  content: "\e97f";
}
.icon-pie-chart:before {
  content: "\e980";
}
.icon-play-circle:before {
  content: "\e981";
}
.icon-plus:before {
  content: "\e982";
}
.icon-plus-circle:before {
  content: "\e983";
}
.icon-pocket:before {
  content: "\e984";
}
.icon-power:before {
  content: "\e985";
}
.icon-printer2:before {
  content: "\e986";
}
.icon-refresh-ccw:before {
  content: "\e987";
}
.icon-refresh-cw:before {
  content: "\e988";
}
.icon-repeat2:before {
  content: "\e989";
}
.icon-rotate-ccw:before {
  content: "\e98a";
}
.icon-rss2:before {
  content: "\e98b";
}
.icon-search4:before {
  content: "\e98c";
}
.icon-send:before {
  content: "\e98d";
}
.icon-settings2:before {
  content: "\e98e";
}
.icon-shopping-bag:before {
  content: "\e98f";
}
.icon-shopping-cart2:before {
  content: "\e990";
}
.icon-speaker:before {
  content: "\e991";
}
.icon-star4:before {
  content: "\e992";
}
.icon-thumbs-up:before {
  content: "\e993";
}
.icon-trash-2:before {
  content: "\e994";
}
.icon-truck4:before {
  content: "\e995";
}
.icon-tv:before {
  content: "\e996";
}
.icon-twitter3:before {
  content: "\e997";
}
.icon-umbrella:before {
  content: "\e998";
}
.icon-unlock:before {
  content: "\e999";
}
.icon-user3:before {
  content: "\e99a";
}
.icon-users:before {
  content: "\e99b";
}
.icon-volume-1:before {
  content: "\e99d";
}
.icon-watch:before {
  content: "\e99e";
}
.icon-x:before {
  content: "\e99f";
}
.icon-x-circle:before {
  content: "\e9a0";
}
.icon-youtube:before {
  content: "\e9a1";
}
.icon-zoom-in:before {
  content: "\e9a2";
}
.icon-search:before {
  content: "\f002";
}
.icon-envelope-o:before {
  content: "\f003";
}
.icon-heart:before {
  content: "\f004";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-home:before {
  content: "\f015";
}
.icon-heart-o:before {
  content: "\f08a";
}
.icon-credit-card2:before {
  content: "\f09d";
}
.icon-truck:before {
  content: "\f0d1";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-paper-plane-o:before {
  content: "\f1d9";
}
.icon-send-o:before {
  content: "\f1d9";
}
.icon-cc-visa:before {
  content: "\f1f0";
}
.icon-cc-mastercard:before {
  content: "\f1f1";
}
.icon-cc-discover:before {
  content: "\f1f2";
}
.icon-cc-amex:before {
  content: "\f1f3";
}
.icon-cc-paypal:before {
  content: "\f1f4";
}
.icon-cc-stripe:before {
  content: "\f1f5";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-user-circle-o:before {
  content: "\f2be";
}
.icon-user-o:before {
  content: "\f2c0";
}
.icon-chevron-down:before {
  content: "\e9a3";
}
.icon-chevron-left:before {
  content: "\e9a4";
}
.icon-chevron-right:before {
  content: "\e9a5";
}
.icon-chevron-thin-down:before {
  content: "\e9a6";
}
.icon-chevron-thin-left:before {
  content: "\e9a7";
}
.icon-chevron-thin-right:before {
  content: "\e9a8";
}
.icon-chevron-thin-up:before {
  content: "\e9a9";
}
.icon-chevron-up:before {
  content: "\e9aa";
}
.icon-chevron-with-circle-left:before {
  content: "\e9ab";
}
.icon-chevron-with-circle-right:before {
  content: "\e9ac";
}
.icon-heart-outlined:before {
  content: "\e9ad";
}
.icon-lifebuoy2:before {
  content: "\e9ae";
}
.icon-list2:before {
  content: "\e9af";
}
.icon-menu2:before {
  content: "\e9b0";
}
.icon-old-mobile:before {
  content: "\e9b1";
}
.icon-shopping-bag2:before {
  content: "\e9b2";
}
.icon-shopping-basket:before {
  content: "\e9b3";
}
.icon-wallet3:before {
  content: "\e9b4";
}
.icon-google:before {
  content: "\e9b5";
}
.icon-add-outline:before {
  content: "\e9b6";
}
.icon-arrow-down:before {
  content: "\e9b7";
}
.icon-arrow-left:before {
  content: "\e9b8";
}
.icon-arrow-right:before {
  content: "\e9b9";
}
.icon-arrow-thin-up:before {
  content: "\e9ba";
}
.icon-chart-bar:before {
  content: "\e9bb";
}
.icon-cog2:before {
  content: "\e9bc";
}
.icon-copy:before {
  content: "\e9bd";
}
.icon-credit-card5:before {
  content: "\e9be";
}
.icon-envelope4:before {
  content: "\e9bf";
}
.icon-indent-increase:before {
  content: "\e9c0";
}
.icon-keyboard:before {
  content: "\e9c1";
}
.icon-list3:before {
  content: "\e9c2";
}
.icon-location-shopping:before {
  content: "\e9c3";
}
.icon-search5:before {
  content: "\e9c4";
}
.icon-shopping-cart3:before {
  content: "\e9c5";
}
.icon-shuffle:before {
  content: "\e9c6";
}
.icon-tablet2:before {
  content: "\e9c7";
}
.icon-user-solid-circle:before {
  content: "\e9c8";
}
.icon-view-show:before {
  content: "\e9ca";
}
.icon-wallet4:before {
  content: "\e9cb";
}
.icon-watch2:before {
  content: "\e9cc";
}
.icon-write:before {
  content: "\e9cd";
}
.icon-clock3:before {
  content: "\e9cf";
}
.icon-forward:before {
  content: "\e9d0";
}
.icon-search6:before {
  content: "\e9d1";
}
.icon-trash:before {
  content: "\e9d2";
}
.icon-envelope5:before {
  content: "\e9d3";
}
.icon-user4:before {
  content: "\e9d4";
}
.icon-sound:before {
  content: "\e9d5";
}
.icon-camera5:before {
  content: "\e9d6";
}
.icon-image:before {
  content: "\e9d7";
}
.icon-cog3:before {
  content: "\e9d8";
}
.icon-book2:before {
  content: "\e9d9";
}
.icon-map-marker:before {
  content: "\e9da";
}
.icon-support2:before {
  content: "\e9db";
}
.icon-tag2:before {
  content: "\e9dc";
}
.icon-heart5:before {
  content: "\e9dd";
}
.icon-cart3:before {
  content: "\e9de";
}
.icon-eye4:before {
  content: "\e9df";
}
.icon-chart2:before {
  content: "\e9e0";
}
.icon-bookmark:before {
  content: "\e9e1";
}
.icon-cross:before {
  content: "\e9e2";
}
.icon-plus2:before {
  content: "\e9e3";
}
.icon-windows:before {
  content: "\e9e4";
}
.icon-switch:before {
  content: "\e9e5";
}
.icon-refresh:before {
  content: "\e9e6";
}
.icon-film3:before {
  content: "\e9e7";
} 
@mixin placeholder-style {
  color:$light;
}
.animatetab .owl-carousel .owl-item {
	-moz-animation: zoomIn 500ms ease;
	-o-animation: zoomIn 500ms ease;
	-webkit-animation: zoomIn 500ms ease;
	animation: zoomIn 500ms ease;
}
/* fonts */

/* end fonts */

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
	width: 100%;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
	padding:0 15px;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
	&:hover {
		z-index:2;
	}
	}
  .owl-carousel .owl-item img {
    display: block;
    max-width: 100%;
    -webkit-transform-style: preserve-3d; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    display: none; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }
  
  input:focus, button:focus, select:focus, textarea:focus {
  text-decoration: none !important;
  box-shadow: none !important;
  outline:0 !important;
}

a {
  &:hover, &:focus {
    outline: 0 !important;
    box-shadow: none !important;
   text-decoration: none;
  }
}
.btn-default, .btn {
  &:hover, &:focus, &:active, &.active {
    outline: 0 !important;
    text-decoration: none !important;
    box-shadow: none !important;
	background:$main;
  }
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.btn-primary.dropdown-toggle.focus, .open>.btn-primary.dropdown-toggle:focus, .open>.btn-primary.dropdown-toggle:hover {
    color: #fff;
    background-color: $main;
    border-color: transparent;
}
.btn-secondary, .btn-tertiary ,.btn-primary{
    color:$light;
    background-color:#333333;
    border-color: transparent;
	box-shadow:none;
	height: 40px;
    line-height: 40px;
    padding: 0 20px;
	&:hover {
		background:$main;
		border-color:$main;
		color:$light;
	}
	@media (max-width: 360px) {
		width: 100%;
	}
}
.btn-secondary:hover, .btn-tertiary:hover,.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    color: $light;
    background-color: $main;
    border-color: transparent;
}
a {
  color:#666666;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none;
}
/* edit from here */
.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a{
	font-size: 12px;
}
.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-active{
	margin: 0;
	background: none;
	border: 0;
	color: $main;
	cursor: pointer;
}

button {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-flag{
	margin: 0;
}

/* edit theme */
* {
	&:focus {
		outline:none !important;
	}
}
img {
	max-width:100%;
	margin:auto;
	height:auto;
	
}
ol, ul, dl {
    margin: 0;
	padding:0;
}
i, cite, em, var, address, dfn {
    font-style: normal;
}

body{
	font-family: 'Khula', sans-serif;
	font-size:13px;
	background:$light; 
}
p {
	font-size:13px;
	color:#666666;
	margin:0;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-size:16px;
	text-transform:capitalize;
    color:#333333;
}
.unvisible ,.headerSpace{
    display: none;
}
/* custom page */
.card  {
	box-shadow:none;
	border:none;
	padding:0;
	text-align: justify;
}
.page-header ,.card-block{
	h1 {
	    color: #333333;
		text-align: left;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		text-transform: none;
		border-bottom:$main_border;
		margin-bottom: .8em;
		padding-bottom: .5em;
	}
}
.page-content.page-cms {
    padding: 1.25rem 0;
}
.contact-form {
    padding:0 0 0 50px;
    color:#666666;
	border-left:$main_border;
}
.form-control {
    background:$light;
    color: #666666;
    border:$main_border;
	font-size:13px;
}
.form-control:focus {
    color: #666666;
    border-color:#333333;
}
.form-control:focus,.input-group.focus {
    outline:0;
}
.input-group .input-group-btn>.btn[data-action=show-password] {
  &:hover {
	background:$main;
  }
}
.form-control-label {
    font-size: 13px;
    text-align: left; 
    color: #666666;
}
.input-group .input-group-btn>.btn {
    padding: .67rem 1rem;
}
.sitemap h2 {
    color: #666666;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    border-bottom: $main_border;
}
.sitemap ul li {
    font-size: 13px;
	&:before {
		content: "\f105";
		font-family: "Font Awesome 5 Free";
		font-size: 14px;
		display: inline-block;
		margin-right: 5px;
	}
}
#stores .page-stores {
  width:100%;
}
#stores .page-stores .store-item {
    padding:0;
}
#stores .page-stores .store-item-container {
	border-bottom: 1px solid hsla(0,0%,63%,.25);
	.h3.card-title {
		font-size:16px;
		color:#333333;
		text-transform:capitalize;
	}
}
#stores .page-stores .store-item-container .store-description {
    font-size: 13px;
    color: #666;
}
#stores .page-stores .store-item-container ul {
    margin-bottom: 0;
    font-size: 13px;
}
#stores .page-stores .store-item-container .divide-left {
    border-left: 1px solid hsla(0,0%,63%,.25);
}
.page-addresses .address, .page-authentication #content {
    box-shadow: none;
    background: #fff;
	border:$main_border;
}
.page-authentication #content {
   max-width:100%;
}
.page-customer-account #content {
    box-shadow: none;
	border:$main_border;
    font-size:13px;
    color: #666666;
}
.page-customer-account #content .no-account {
    text-align: center;
    font-size: 14px;
}
.custom-checkbox input[type=checkbox]+span {
    margin-top: 5px;
}
.label, label {
    font-size: 13px;
    color: #666666;
}
.page-my-account #content .links a span.link-item {
    box-shadow: none;
    border:$main_border;
    font-size: 14px;
}
.separator {
    border-color:#d9d9d9;
}
.cart-summary,#js-checkout-summary{
	border:$main_border;
}
.cart-summary-line .label,.cart-summary .label,.cart-summary-line.cart-summary-subtotals .label, .cart-summary-line.cart-summary-subtotals .value  {
    text-transform: capitalize;
    font-weight: 600;
}
.cart-summary-line .value {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
}
.cart-summary-products p {
	font-weight:600;
}
.cart-grid-body .card-block {
    padding: 0;
}
.cart-item {
	border-bottom:$main_border;
}
body#checkout section.checkout-step {
    box-shadow:none;
    border-bottom:$main_border;
}
body#checkout section.checkout-step .step-title {
    text-transform: capitalize;
}
.checkout-inner {
	border:$main_border;
}
.block-reassurance-item {
	.h6 {
		font-size:13px;
	}
}
#block-reassurance li {
    border-bottom: $main_border;
}
/* end custom page */
@media (min-width:1200px) {
	.container-fluid {
		padding-left:80px;
		padding-right:80px;
	}
	.container {
		max-width: 1170px;
		padding-left:0;
		padding-right:0;
	}
}
.container {
	width:auto;
}
main {
	overflow:hidden;
}
#wrapper{
	margin: 0;
	background:transparent;
	box-shadow: none;
	padding: 0;
	#main .page-footer {
		margin:0;
	}
}
.back-top {
	display: block;
	position: fixed;
	right: 25px;
	bottom: 25px;
	overflow: hidden;
	z-index: 9;
	a {
		background: $main;
		color: $light;
		width: 52px;
		height: 52px;
		line-height: 54px;
		border-radius: 5px;
		font-size: 14px;
		text-transform: uppercase;
		display: block;
		text-align: center;
		text-decoration: none;
		-webkit-transition: all 500ms ease-in-out;
		-moz-transition: all 500ms ease-in-out;
		-o-transition: all 500ms ease-in-out;
		transition: all 500ms ease-in-out;
		&:hover {
			background: #333;
		}
	}
}
/* ===== edit theme ======== */
@media(min-width: 992px){
	#header .header-top.scroll-menu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1001;
	    background: rgba(255, 255, 255, 0.7);
		border:none;
		padding:10px 0;
		@include pos-transition(300ms);
		-webkit-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.4);
		-moz-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.4);
		box-shadow: 0 8px 6px -6px rgba(0,0,0,0.4);
		&:hover {
			background: rgba(255, 255, 255, 1);
			@include pos-transition(300ms);
		}
	}
}
#header {
	position:relative;
	z-index:9;
	@media(max-width: 767px){
		text-align:center;
		background:transparent;
	}
	.header-nav {
		background:#f4f4f4;
		max-height:none;
		border-bottom: 1px solid #dbdbdb;
		margin:0;
		@media(max-width: 991px){
			text-align:center;
		}
	}
	.header-top {
		padding: 0;
		@media(max-width:991px){
			text-align:center;
		}
		@media(max-width: 767px){
			background:transparent;
		}
		.header_logo  {
			//padding: 20px 15px;
          width: 11rem;
			img {
				margin:0;
				@media(max-width: 991px){
					margin:auto;
				}
			}
		}
	}
	.header-bottom {
		position:relative;
		margin-bottom:30px;
		background:#333;
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15); 
		@media(max-width: 991px){
			padding: 5px 0;
		}
	}
}
#index  .header-bottom {
	margin-bottom:0;
}
/* selector */
.selector-block {
	float:right;
	margin-left:5px;
	@media (max-width:767px ) {
		display: inline-block;
		float: none;	
	}
	.selector-label {
	}
	.localiz_block {
		margin:0 !important;
		display:inline-block;
		&:hover {
			button,.current-block {
				color:$main;
				@include pos-transition(300ms);
				.expand-more {
					color:$main;
					@include pos-transition(300ms);
				}
			}
		}
		button,.current-block {
		    font-weight: 400;
			padding: 0 15px;
			color:#666666;
			text-shadow: none;
			cursor: pointer;
			line-height:41px;
			font-size:13px;
			display: inline-block;
			text-transform:capitalize;
			@include pos-transition(300ms);
			.expand-more {
				color:#666;
				@include pos-transition(300ms);
			}
			i{
				font-size: 16px;
			}
			img {
				margin-right:5px;
			}
			
		}
		.dropdown-menu {
			margin:0;
			top:100%;
			right: 0;
			left:auto;
			min-width:100%;
			overflow:hidden;
			padding:10px;
			background:$light;
			border-radius: 0;
			border:$main_border;
			li {
				padding:0 ;
				@include pos-transition(300ms);
				.dropdown-item {
					padding:0 10px;
					color: #636363;
					line-height: 22px;
					font-size: 12px;
					img {
						display: inline-block;
						margin-right: 5px;
						vertical-align: -2px;
					}
				}
				&.current .dropdown-item{
					color:$light;
					background:$main;
					@include pos-transition(300ms);
				}
				&:hover .dropdown-item{
					color:$light;
					background:$main;
					@include pos-transition(300ms);
				}
			}
		}
	}
}
.user-info-block{
	display: inline-block;
	float: right;
	@media (max-width: 767px) {
		float: none;
	}
	.user-text{
		font-size: 13px;
		color: #666;
		line-height: 41px;
		text-transform: capitalize;
		cursor: pointer;
		vertical-align: top;
		&:after{
			
		}
	}
	i{
		margin-right: 7px;
		font-size: 16px;
		line-height: 36px;
		display: inline-block;
	}
	ul.dropdown-menu{
		li{
			padding: 0 15px;
			line-height: 25px;
			a{
				&:hover{
					color: $main;
				}
			}
		}
	}
}

/* end selector */
/* module contact */
.block-contact-top {
	position:relative;
	float: right;
	padding-top: 14px;
	@media (max-width:767px ) {
		float: none;
		display: inline-block;
		padding-top: 20px;
	}
	.contact_cms {
		overflow:hidden;
		span {
		    text-transform: capitalize;
			font-size: 16px;
			line-height:30px;
			font-weight: 700;
			color: #fff;
			display: block;
			vertical-align: top;
			i{
				font-size: 24px;
				color: $main;
				margin-right: 5px;
				vertical-align: top;
			}
		}
	}
}
/* end module contact */

/* module user-info */
	.user_info_top {
		float:right;
		font-size:0;
		@media (max-width:767px ) {
			width:100%;
			display:inline-block;
			float:none;
		}
		> li {
			display:inline-block;
			vertical-align:top;
			margin: 0 5px;
			line-height:1;
			&:last-child a {
				border:none;
			}
			a {
				display: inline-block;
				overflow: hidden;
				float: left;
				line-height: 35px;
				padding: 0 15px 0 30px;
				font-size: 13px;
				position: relative;
				color: #666666;
				i {
					position: absolute;
					top: 0;
					left: 3px;
					display: block;
					font-size: 14px;
					line-height: 35px;
					color: #999999;
					@include pos-transition(300ms);
				}
				&:hover {
					color:$main;
					i {
					    left: 7px;
						color:$main;		
						@include pos-transition(300ms);
					}
				}
			}
		}
	}
/* end module user-info */
/* module megamenu */
//.megamenu {
//	position:relative;
//	z-index:1;
//}
//.nav-container{
//	display: inline-block;
//	padding: 20px 0;
//	vertical-align: bottom;
//}
//.pt_custommenu {
//    position: relative;
//	.pt_menu {
//		//float:left;
//		.parentMenu a {
//		    display: block;
//			line-height:63px;
//			padding:0 10px;
//			height:63px;
//			font-size: 15px;
//			font-weight: 700;
//			color: #333;
//			background: transparent;
//			text-transform: uppercase;
//			position: relative;
//			i {
//				font-size: 11px;
//				padding: 0 0 0 7px;
//				display: none;
//				&:before {
//					content: "\f107";
//					font-family: "Font Awesome 5 Free";
//				}
//			}
//		}
//		&.act .parentMenu a,&.active .parentMenu a {
//			color:$main;
//		}
//		&:first-child .parentMenu a {
//			padding-left:0;
//		}
//		.popup {
//			position: absolute;
//			box-shadow: 0px 3px 9.3px 0.7px rgba(0, 0, 0, 0.15);
//			background:$light;
//			padding:30px 20px;
//			z-index:10;
//			text-align:left;
//			@media (max-width:1199px) {
//				left: 0 !important;
//			}
//			.cms_mega a img {
//				-webkit-transition: all 1700ms ease;
//				-moz-transition: all 1700ms ease;
//				-o-transition: all 1700ms ease;
//				transition: all 1700ms ease;
//			}
//			.cms_mega a:hover img {
//				-webkit-transform: scale(1.07);
//				-moz-transform: scale(1.07);
//				-ms-transform: scale(1.07);
//				-o-transform: scale(1.07);
//				transform: scale(1.07);
//			}
//			.block1 {
//				overflow: hidden;
//				float: left;
//				.column {
//					float: left;
//					width: 200px;
//					padding-right:20px;
//					margin-bottom: -99999px;
//					padding-bottom: 99999px;
//					@media (max-width:1199px) {
//						width: 170px;
//					}
//					&.last {
//						padding-right:0;
//					}
//					.itemMenuName {
//						color:$main;
//						line-height: 35px;
//						text-transform: capitalize;
//						font-weight: 600;
//						font-size: 14px;
//						display:block;
//						&:hover {
//							color:$main;
//						}
//					}
//					.itemSubMenu {
//						padding: 0;
//						.itemMenuName {
//							color:#2c2e3d;
//							text-transform: capitalize;
//							line-height: 30px;
//							font-weight: 400;
//							font-size: 13px;
//							&:hover {
//								color:$main;
//							}
//						}
//					}
//				}
//			}
//		}
//	}
//
//}


.pt_custommenu_mobile {
    text-align: left;
    position: relative;
    clear: both;
	.navbar {
		min-height:40px;
		margin: 0;
		border: 0;
		position: relative;
		overflow: hidden;
		background: #333333;
		padding: 0 15px;
		overflow: hidden;
		cursor:pointer;
		.navbar-inner .btn-navbar {
			display: block;
			overflow: hidden;
			margin: 0;
			color: #fff;
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 0.025em;
			text-transform: uppercase;
			line-height: 40px;
			&:after {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: 40px;
				height: 40px;
				text-align: center;
				color: #fff;
				font-size: 15px;
				font-weight: 900;
				font-family: "Font Awesome 5 Free";
				cursor:pointer;
			}
		}
		.navbar-inner.navbar-inactive .btn-navbar:after {
			content: "\f03a";
		}
		.navbar-inner.navbar-active .btn-navbar:after {
			content: "\f03a";
		}
	}
}

ul#pt_custommenu_itemmobile {
    overflow: hidden;
    margin: 0 0 15px;
    padding: 0;
	li {
		cursor: pointer;
		list-style-type: none;
		margin: 0;
		padding:0 10px;
		display: block;
		position: relative;
		border-bottom:1px solid rgba(255,255,255,0.1);
		&:last-child {
			border:0;
		}		
		a {
			line-height: 45px;
			color:#fff;
			text-transform: capitalize;
			text-decoration: none;
			font-size: 15px;
			font-weight: 400;
			&:hover {
				color: $main;
			}
		}
		.grower {
			position: absolute;
			top: 0;
			right: 0;	
			a:after {
				display: block;
				width: 45px;
				height:45px;
				text-align: center;
				color: #fff;
				font-size: 13px;
				font-weight: 900;
				font-family: "Font Awesome 5 Free";
				 content: "\f055";
			}
			&.active a:after {
				content: "\f056";
			}
		}
	
	}
}

/* end module megamenu */
/* module vegamenu */
.navleft-container {
	position:relative;
	z-index:9;
	.pt_vmegamenu_title{
		position: relative; 
		cursor: pointer;
		padding:0 20px;
		height: 53px;
		background: $main;
		&:before {
			display:inline-block;
			content: "\f03c";
			font-family: $font-icon;
			font-size: 16px;
			font-weight: 900;
			line-height: 1;
			margin-right: 10px;
			color:$light;
			float:left;
			line-height:53px;
		}
		&:after {
			display: block;
			content: "\f078";
			color: #fff;
			position: absolute;
			top: 0;
			right: 20px;
			line-height: 53px;
			font-family: "Font Awesome 5 Free";
			font-size: 14px;
			font-weight: 900;
		}
		h2{
			margin: 0;
			color: $light;
			padding: 0;
			text-transform: uppercase;
			font-size: 16px;
			@media (max-width:1199px) {
				font-size:14px;
			}
			font-weight: 700;
			overflow:hidden;
			line-height:57px;
			@include pos-transition(300ms);
			span {
				text-transform: uppercase;
				font-size: 11px;
				display:block;
				line-height:15px;
			}
			
			i {display:none; } 
		}
	}
	.pt_vmegamenu{
		display:none;
		width: 100%;
		background: #fff;
		border: 1px solid #e8e8e8;
		border-top:none;
		padding:0;
		position: absolute;
		top: 100%;
		left: 0;
		z-index:9;
		.pt_menu {
			position: relative;
			&:hover .wrap-popup{
				opacity:1;
			}
			&:not(.noSub):after{
				display: block;
				position: absolute;
				top: 0;
				right: 15px;
				line-height: 56px;
				content: "\f067";
				color: #777;
				font-size: 11px;
				font-weight: 700;
				font-family: "Font Awesome 5 Free";
				background:transparent;
				@include pos-transition(300ms);
			}
			&.pt_menu_cms:after {
				display:none;
			}
			&.active .parentMenu a,&:hover:not(.noSub):after{
				color:$main;
				@include pos-transition(300ms);
			}
			
			.parentMenu a{
				position:relative;
				display: block;
				overflow: hidden;
				line-height:56px;
				border-bottom: 1px solid #f1f1f1;
				text-transform: capitalize;
				word-wrap: break-word;
				background:$light;
				color:#666666;
				padding: 0 20px;
				@include pos-transition(300ms);  
				@media (max-width:1199px) {	
				}
				font:{
					size: 16px;
					weight:400;

				}	
				@media (max-width:1199px) {
					
				}				
				.cate-thumb{
					float: left;
					width:40px;
					margin-right: 10px; 
					padding:0;
					border-right:$main_border;
					@media (max-width:1199px) {
						display:none;
					}
				}
				span:not(.cate-thumb){
					
				}
				&.view_more {
					&:before {
						display:none;
					}
					cursor:pointer;
					line-height:32px;
					background: #666666;
					color:$light;
					&:hover {
						background: #3b3b3b;
						color:$light;
						@include pos-transition(300ms);
					}
					i {
						margin-right: 5px;
						font-size: 20px;
						display: inline-block;
						width: 40px;
						text-align: center;
						margin-right: 10px;
						display:none;
					}
				}
			}
			
			&.act, &.active{
				&:after{
				
				}
				.parentMenu a{
					color:$main;
					@include pos-transition(300ms);
				}
			}
			.wrap-popup{
				position: absolute;
				top: 0;
				left: 100%;
				z-index:1;
				opacity:0;
				width: 200px * 4 + 60px;
				.popup{
					display: none;
					position: absolute;
					top: 0 !important;
					left: 0 !important;
					width: auto !important;
					border: 1px solid #e8e8e8;
					border-top:2px solid $main;
					.box-popup{
						background: #fff;
						padding: 20px;
						float: left;
						overflow: hidden;
						.column{
							width: 200px;
							padding:0 15px;
							margin-bottom: -99999px;
							padding-bottom: 99999px;
							.itemMenuName{
								color: #666666;
								line-height: 35px;
								text-transform: uppercase;
								font-weight: 600;
								font-size: 15px;
								display:block;
								
								&:hover{
									color:$main;
								}
								&.nochild {
								    color: #666;
									text-transform: capitalize;
									font-weight: 400;
									font-size: 14px;
									&:hover {
										color:$main;
									}
								}
							
							}
							.itemSubMenu{
								padding-bottom:20px;
								.itemMenuName{
								    color: #666;
									text-transform: capitalize;
									font-weight: 400;
									font-size: 14px;
									&:hover{
										color:$main;
									}
									&:after{
										display:none;
									}	
								}
							}
							
						}
						.column.last { 
							padding-right:0;
							border:0;
							
						}
						.column.blockright {
							width:300px;
						}
					}
					.block1 {
						overflow:hidden;
					}
					.block2 {
						display:inline-block;
						width:100%;
						margin-top:20px;
					}
				}
			}
		}
		
		
	}
}
/* end module vegamenu */

/* module search category */
.recherche-par-categorie {
  width: 16%;
  #pos_search_top{
      ::-webkit-input-placeholder {
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
      }
      ::-moz-placeholder {
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
      }
      :-ms-input-placeholder {
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
      }
      :-moz-placeholder {
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
      }
      //padding:4px 5px;
      @media(max-width:991px){
          padding:0;
      }
      .form_search{
          padding: 0;
          position: relative;
          width: 100%;
          //float:left;
          height:44px;
          color:#fff;
          label{
              display: none;
          }
          .search-block {
              height:50px;
              line-height:50px;
          }
          .form-control{
              //padding:10px 240px 10px 20px;
              width:100%;
              //height: 44px;
              height: 40px;
              padding-left: 3rem;
              padding-right: 3rem;
              font-size: 13px;
              //background: $light;
              background: $main;
              color:#fff;
              border: none;
              //-webkit-border-radius: 3px;
              //-moz-border-radius: 3px;
              //-ms-border-radius: 3px;
              //-o-border-radius: 3px;
              //border-radius: 3px;
              &:focus {
                  box-shadow:none;
              }
              @media (max-width:479px) {
                  padding-right:0;
              }
          }
          .search_submit{
              position: absolute;
              top: 0;
              //right: 0;
              left: 0;
              height: 44px;
              //line-height: 50px;
              line-height: 44px;
              text-align: center;
              width: 45px;
              color: #fff;
              background: none;
              padding: 0;
              border: 0;
              -webkit-border-radius: 0px 6px 6px 0px;
              -moz-border-radius: 0px 6px 6px 0px;
              -ms-border-radius: 0px 6px 6px 0px;
              -o-border-radius: 0px 6px 6px 0px;
              border-radius: 0px 6px 6px 0px;
              @include pos-transition(300ms);
              i {
                  font-size: 16px;
                  display: inline-block;
              }
              &:hover{
                  color: $main;
                  @include pos-transition(300ms);
              }

          }
          .form-group{
              position:absolute;
              right:70px;
              top:1xp;
              display:inline-block;
              width: 150px;
              margin:0;
              background:transparent;
              .bootstrap-select{
                  width: 150px;
                  height:43px;
                  line-height:46px;
                  margin:0;
                  border:0;
                  padding:0 15px;
                  color:#fff;
                  font-size:13px;
                  border-left: 1px solid #d9d9d9;
                  -webkit-user-select: none;
                  -moz-user-select: -moz-none;
                  -ms-user-select: none;
                  user-select: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                   background: url(../img/option_search.png) no-repeat 75% 50% $light;
                  @media (max-width:480px ) {
                      background: url(../img/option_search.png) no-repeat 85% 50% $light;
                  }
              }
          }
      }
  }
}
@media (max-width:480px ) {
	#pos_search_top{
		.form_search{
			.form-control{
				font-size:10px;
				padding: 0 180px 0 10px;
			}
			.form-group{
				width: 110px;
				.bootstrap-select{
					width: 110px;
					font-size:10px;
				}
			}
		}
	}
	
}
.ui-corner-all.ui-widget-content {
	max-height:380px;
	overflow:auto;
	border:$main_border;
}
/* end module search category */

.blockcart {
	float: right;
	position: relative;
	@media (max-width:991px ) {
		float:none;
		display:inline-block;
	}	
	.header {
		width: 114px;
		padding: 12px 0;
		text-align: center;
		background: $main;
		&:hover a {

		}
		a {
			display: block;
			position: relative;
			color: #fff;
			font-size:16px;
			font-weight:700;
			i{
				font-size: 34px;
			}
			.item_txt {
				display:block;
				line-height: 27px;
			}
			.item_count {
				position: absolute;
				top: 0;
				right: 23px;
				display: block;
				overflow: hidden;
				width: 25px;
				height: 25px;
				line-height: 28px;
				font-weight: 700;
				color: white;
				background: #333;
				text-align: center;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border-radius: 100%;
			}
			.item_total {
				color:#fff;
			}
			&:hover {
			  
			}
		}
	}
	.body {
		margin:0;
		padding: 0;
		background: $light;
		width:355px;
		@media (max-width:479px) {
			width:270px;
		}
		top:100%;
		left: auto;
		right: 0;
		border:$main_border;
		border-top:2px solid $main;
		position:absolute;
		display: none;
		z-index:99;
		@media (max-width:991px ) {
			right: 50%;
			-moz-transform: translateX(50%);
			-webkit-transform: translateX(50%);
			-o-transform: translateX(50%);
			-ms-transform: translateX(50%);
			transform: translateX(50%);
		}
		ul  {
			padding:0 20px;
			li {
				padding: 20px 0;
				border-bottom:$main_border;
				overflow: hidden;
				.img_content {
					float: left;
					margin: 0 20px 0 0;
					position: relative;
					.product-quantity {
						position: absolute;
						top: 5px;
						left: 5px;
						min-width: 25px;
						line-height: 25px;
						-webkit-border-radius: 100%;
						-moz-border-radius: 100%;
						border-radius: 100%;
						padding:0;
						text-align: center;
						background: $main;
						color: $light;
						font-size: 14px;
						display:inline-block;
					}
				}
				.right_block {
					overflow: hidden;
					position: relative;
					padding: 0 15px 0 0;
					text-align: left;
					span {
						font-size:13px;
					}
					.product-name {
						display: block;
						overflow: hidden;
						word-wrap: break-word;
						text-overflow: ellipsis;
						white-space: nowrap;
						color:#666666;
						font-weight: 600;
						cursor: pointer;
						text-transform: capitalize;
						font-size: 16px;
						line-height: 20px;
						&:hover {
							color:$main;
						}
					}
					.product-price {
						display: block;
						margin: 5px 0 0;
						font-size: 14px;
						font-weight: 700;
						color: #f46a11;
					}
					.remove-from-cart {
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						color:#777;
						&:hover {
							color:#333;
						}
					}
					.attributes_content {
						display: block;
						font-size: 16px;
						line-height:20px;
						color: #777;
						margin: 5px 0 0;
					}
					strong {
						font-weight:400;
					}
				}
			}
		}
		.price_content {
			overflow: hidden;
			padding: 20px;
			.price_inline {
				overflow: hidden;
				line-height: 25px;
				.label {
					float: left;
					font-weight: 600;
					color:#333;
					font-size:14px;
				}
				.value {
					float: right;
					font-weight: 600;
					color:#333;
					font-size:14px;
				}
			}
		}
		.checkout {
			a {
				display:block;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 24px;
				color: #fff;
				height: 52px;
				line-height: 57px;
				box-shadow: none;
				padding: 0 15px;
				background: #454545;
				text-align: left;
				&:before{
					content: "\f00c";
					font-family: 'Font Awesome 5 Free';
					font-size: 18px;
					display: inline-block;
					float: right;
					margin-right: 5px;
					line-height: 51px;
					font-weight: 900;
					color: #fff;
				}
				&:hover {
					background:$main;  
					border-color:$main;		
					color:$light;
				}
			}
		}
	}
}
@media (min-width: 992px) { 
	.blockcart .body  ul{
		max-height: 358px;
		overflow-y:auto;
		

	}
}

.blockcart .body  ul::-webkit-scrollbar-track
{
	background-color:$main;
}

	.blockcart .body  ul::-webkit-scrollbar
{
	width: 4px;
	background-color: $main;
}

	.blockcart .body  ul::-webkit-scrollbar-thumb
{
	background-color: rgba(46, 46, 46, 0.85);
	border-radius: 10px;
}
	/* style layer cart */
	#blockcart-modal .modal-body {
		background:$light;
		padding: 3.125rem 1.875rem;
	}
	#blockcart-modal .divide-right {
		border-right:$main_border;
		p , strong {
			color:#333333;
			font-size:14px;
			font-weight:600;
		}
	}
	#blockcart-modal .cart-content { 
		p ,strong{
			color:#333333;
			font-size:14px;
		}
		strong {
			font-weight:600; 
		}
	}
	#blockcart-modal .product-name {
		font-size: 16px;
		margin-bottom: 20px;
		color:#333333;
	}
	#blockcart-modal .modal-header {
		background: $main;
	}
	/* end style layer cart */
#blockcart-modal button.close {
	color:$light;
	opacity:1;
}	
#blockcart-modal .cart-content .btn {
    margin-top: 20px;
}	
#blockcart-modal .cart-content .cart-content-btn{
	@media (max-width: 360px) {
		display: block;
	}
}

/* end module cart block */

/* module slideshow */

.nivo-html-caption {
    display: none;
}
@-webkit-keyframes myfirst {
		from {width: 0;}
		to {width: 100%;}
	}
	@keyframes myfirst {
		from {width: 0;}
		to {width: 100%;}
	}
	.timethai.pos_hover{
		-webkit-animation-play-state: paused;
		animation-play-state: paused;
		
}

.slideshow_container  .pos-slideshow{
	position: relative;
	&:hover .nivo-directionNav a {
		opacity:1;
		@include pos-transition(300ms);
	}
}
.slideshow_container .pos-slideshow .nivo-directionNav a {
	    display: block;
		background: url(../img/bg_slide_next.png) no-repeat;
		width: 20px;
		height: 29px;
		color: #d64444;
		text-align: center;
		font-size:0;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		top: 50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		opacity:0;
		 -webkit-transition: none;
		-moz-transition: none;
		-o-transition: none;
		transition: none;
		i {
			font-size:24px;
			line-height:80px;
			display: none;
		}
		@media (max-width:767px) {
			display: none;
		}
		&:hover{
			
		}
		&.nivo-prevNav{
			left:20px;
			background: url(../img/bg_slide_back.png) no-repeat;
			&:hover{
				background-position: 0 100%;
			}
		}
		&.nivo-nextNav{
			right:20px;
			&:hover{
				background-position: 0 100%;
			}
		}
}
.slideshow_container .pos-slideshow .nivo-controlNav {
		position: absolute;
		bottom:20px;
		@media (max-width:1199px) {
			bottom:get1024-vw(20px);
		}
		left:0;
		right:0;
		text-align:center;
		padding:0 30px;
		z-index: 8;
		@media (max-width: 767px) {
			display: none;
		}
		a{
			display: inline-block;
			margin: 0 3px;
			cursor: pointer;
			font-size: 0;
			width: 15px;
			height: 15px;
			background: transparent;
			border: 2px solid #fff;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			border-radius: 100%;
			@include pos-transition(300ms);	
			&.active,&:hover{
				background:$light;
			}
		}
}
.banner7-des {
	p {
		margin:0;
	}
}
.desc1 {
	max-width:52%;
	text-align: center;
	position:absolute;
	top:50%;
	margin-left: 50px;
	z-index:8;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	line-height:1;
	//@media (min-width: 1600px) {
	//	margin-top: -75px;
	//}
	.title1 {
		font-size: 16px;
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 36px;
		letter-spacing: 0.25em;
		-webkit-animation: zoomInRight 800ms ease-in-out;
		-moz-animation: zoomInRight 800ms ease-in-out;
		-ms-animation: zoomInRight 800ms ease-in-out;
		animation: zoomInRight 800ms ease-in-out;
	}
	.title2{
		font-size: 90px;
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		-webkit-animation: zoomInRight 1200ms ease-in-out;
		-moz-animation: zoomInRight 1200ms ease-in-out;
		-ms-animation: zoomInRight 1200ms ease-in-out;
		animation: zoomInRight 1200ms ease-in-out;
	}
	.title3{
		font-size: 50px;
		text-transform: uppercase;
		font-weight: 700;
		padding: 0;
		color: #fff;
		margin-bottom: 35px;
	    -webkit-animation: zoomInRight 1600ms ease-in-out;
		-moz-animation: zoomInRight 1600ms ease-in-out;
		-ms-animation: zoomInRight 1600ms ease-in-out;
		animation: zoomInRight 1600ms ease-in-out;
	}
	.title4{
		font-size: 34px;
		//text-transform: capitalize;
		font-weight: normal;
		padding: 0;
		color: #fff;
	    -webkit-animation: zoomInRight 2000ms ease-in-out;
		-moz-animation: zoomInRight 2000ms ease-in-out;
		-ms-animation: zoomInRight 2000ms ease-in-out;
		animation: zoomInRight 2000ms ease-in-out;
	}
	.readmore  {
		margin: 30px 0 0;
	    -webkit-animation: zoomInRight 2400ms ease-in-out;
		-moz-animation: zoomInRight 2400ms ease-in-out;
		-ms-animation: zoomInRight 2400ms ease-in-out;
		animation: zoomInRight 2400ms ease-in-out;
		a {
			@include pos-transition(300ms);
			font-size: 14px;
			//background: #fff;
			//color: #333333;
            background: $main;
            color: #fff;
			line-height: 59px;
			text-transform: uppercase;
			font-weight: 900;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			border-radius: 3px;
			&:hover {
			    //background: $main;
				//color: #fff;
                opacity: .8;
				@include pos-transition(300ms);
			}
		}
	}
}
 .desc2 {
	max-width: 52%;
	position:absolute;
	text-align: center;
	top:50%;
	margin-left: 50px;
	z-index:8;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	line-height:1;
	@media (min-width: 1600px) {
		margin-top: -75px;
	}
	.title1 {
		font-size: 16px;
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 36px;
		letter-spacing: 0.25em;
		-webkit-animation: zoomInUp 800ms ease-in-out;
		-moz-animation: zoomInUp 800ms ease-in-out;
		-ms-animation: zoomInUp 800ms ease-in-out;
		animation: zoomInUp 800ms ease-in-out;
	}
	.title2{
		font-size: 90px;
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		-webkit-animation: zoomInUp 1200ms ease-in-out;
		-moz-animation: zoomInUp 1200ms ease-in-out;
		-ms-animation: zoomInUp 1200ms ease-in-out;
		animation: zoomInUp 1200ms ease-in-out;
	}
	.title3{
		font-size: 50px;
		text-transform: uppercase;
		font-weight: 700;
		padding: 0;
		color: #fff;
		margin-bottom: 35px;
	    -webkit-animation: zoomInUp 1600ms ease-in-out;
		-moz-animation: zoomInUp 1600ms ease-in-out;
		-ms-animation: zoomInUp 1600ms ease-in-out;
		animation: zoomInUp 1600ms ease-in-out;
	}
	.title4{
		font-size: 34px;
		text-transform: capitalize;
		font-weight: normal;
		padding: 0;
		color: #fff;
	    -webkit-animation: zoomInUp 2000ms ease-in-out;
		-moz-animation: zoomInUp 2000ms ease-in-out;
		-ms-animation: zoomInUp 2000ms ease-in-out;
		animation: zoomInUp 2000ms ease-in-out;
	}
	.readmore  {
		margin: 30px 0 0;
	    -webkit-animation: zoomInUp 2400ms ease-in-out;
		-moz-animation: zoomInUp 2400ms ease-in-out;
		-ms-animation: zoomInUp 2400ms ease-in-out;
		animation: zoomInUp 2400ms ease-in-out;
		a {
			@include pos-transition(300ms);
			font-size: 14px;
			color: #333333;
			line-height: 59px;
			text-transform: uppercase;
			font-weight: 900;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			background: #fff;
			border-radius: 3px;
			&:hover {
			    background: $main;
				color: #fff;
				@include pos-transition(300ms);
			}
		}
	}
}
/* responsive slideshow */
	@media (max-width:1199px) {
	.banner7-des {
		
	}
	.desc1,.desc2,.desc3{
		max-width:55%;
		left: 6%;
		.title1 {
			 font-size:get1024-vw(16px);
		}
		.title2{
			font-size:get1024-vw(50px);
		}
		.title3{
			font-size:get1024-vw(30px);
			margin:0 0 get1024-vw(20px);
		}
		.title4{
			font-size:get1024-vw(18px);				
		}
		.readmore  {
			margin-top: get1024-vw(30px);
			a {
				font-size:get1024-vw(12px);
				line-height:get1024-vw(40px);
				padding:0 get1024-vw(30px);
			}
		}
	}	
	.desc2{
		.title4{
			font-size:get1024-vw(18px);				
		}
	}
}
@media (max-width:480px) {
	.desc1,.desc2,.desc3{
		max-width:100%;
		.title1 {
			 font-size:get480-vw(13px);
			 margin: 0 0 get480-vw(5px);	
		}
		.title2{
			font-size:get480-vw(20px);
		}
		.title3{
			font-size:get480-vw(20px);			
		}
		.title4{
			font-size:get480-vw(18px);	
			margin: get480-vw(5px) 0 0;	
		}
		.title5{
			font-size:get480-vw(10px);	
		}
		.readmore  {
			a {
				font-size:get480-vw(10px);
				line-height:get480-vw(30px);
				padding:0 get480-vw(20px);
			}
		}
	}
}
/* end responsive slideshow */

/* end module slideshow */

/* module postabproductslider */
.product-tabs-container-slider {
	margin-top: 60px;
	ul.tabs_slider {
		position: relative;
		display: inline-block;
		width: 100%;
		padding-bottom: 15px;
		border-bottom: 1px solid #e5e5e5;
		li {
			display: inline-block;
			position: relative;
			z-index: 2;
			background: white;
			margin-right: 55px;
			line-height: 1;
			cursor: pointer;
			color: #333333;
			text-transform: uppercase;
			font-size: 24px;
			font-weight: 400;
			span {
				position:relative;
				display:inline-block;
			}
			@include pos-transition(300ms);
			&:last-child {
				margin:0;
			}
			&.active,&:hover {
				font-weight: 700;
				@include pos-transition(300ms); 
			}
			@media ( max-width:480px) {
				margin-bottom:20px;
			}
		}
		
	}
	.owl-dots{
		position: absolute;
		right: 15px;
		top: -33px;
	}
}


/* end  postabproductslider*/

/* module tabcateslider */
.tab-category-container-slider {
	margin-top: 60px;
	.pos_tab{
		padding-bottom:15px;
		border-bottom: 1px solid #e5e5e5;
		display:inline-block;
		width: 100%;
		h2{
			display: inline-block;
			float: left;
			font-size: 24px;
			text-transform: uppercase;
			margin: 0;
		}
		ul.tab_cates {
			position:relative;
			display:inline-block;
			float: right;
			padding-right: 108px;
			margin-top: 6px;
			@media ( max-width:767px) {
				padding-right: 0;
			}
			li {
				display:inline-block;
				position: relative;
				z-index: 2;
				background: $light;
				margin-right:26px;
				line-height:1;
				cursor:pointer;
				color:#333333;
				text-transform:capitalize;
				font:{
					size:18px;
					weight:400;
				}
				span{
					display: inline-block;
				}
				@include pos-transition(300ms);
				&:last-child {
					margin:0;
				}
				&.active,&:hover {
					font-weight: 700; 
					@include pos-transition(300ms);
				}
				
			}
		}
	}
}
/* end module tabcateslider */

/* module special-products */
.pos-special-products  {
	.pos_title{
		background: #ebebeb;
		border: none;
		h2{
			padding-bottom: 0;
			background: $main;
			line-height: 60px;
			color: #fff;
			padding: 0 30px;
			position: relative;
			@media (max-width: 480px) {
				padding: 0 15px;
			}
			&:before{
				background: transparent linear-gradient(110deg, #0084c5 50%, #ebebeb 50%) repeat scroll 0 0;
				content: "";
				display: block;
				height: 60px;
				right: -25px;
				position: absolute;
				top: 0;
				width: 35px;
				z-index: -1;
				@media (max-width: 480px) {
					display: none;
				}
			}
			span{
				vertical-align: top;
				display: inline-block;
				&:before{
					display: inline-block;
					content: "\f1da";
					font-family: "Font Awesome 5 Free";
					font-size: 28px;
					font-weight: 900;
					line-height: 60px;
					margin-right: 10px;
					color: white;
					vertical-align: top;
					@media (max-width: 480px) {
						display: none;
					}
				}
			}
		}
		p.text-title{
			display: inline-block;
			color: #333;
			font-size: 20px;
			line-height: 60px;
			margin-left: 40px;
			@media (max-width: 768px) {
				margin-top: 20px;
				margin-bottom: 20px;
				margin-left: 15px;
				line-height: inherit;
				font-size: 16px;
			}
		}
	}
	.owl-dots{
		top: -35px;
		right: 30px;
	}
	.js-product-miniature{
		.img_block{
			width: 46%;
			display: inline-block;
			float: left;
			@media (max-width: 480px) {
				width: 100%;
			}
		}
		.product_desc{
			text-align: left;
			padding-left: 20px;
			@media (max-width: 480px) {
				padding-left: 0;
				overflow: inherit;
			}
			.text-hurryup{
				font-size: 14px;
				color: $main;
				text-transform: uppercase;
			}
			.manufacturer{
				margin-top: 24px;
			}
			.product_name{
				margin-top: 7px;
				font-size: 20px;
			}
			.product-desc{
				display: block;
				padding: 0;
			}
			.price-box{
				margin-top: 30px;
			}
			.cart{
				margin-top: 30px;
				button.ajax_add_to_cart_button, span.ajax_add_to_cart_button{
					display: inline-block;
					border: 1px solid #e8e8e8;
					color: #666;
					padding: 0 30px;
					line-height: 41px;
					background: transparent;
					height: 40px;
					text-transform: uppercase;
					cursor: pointer;
					font-size: 13px;
					font-weight: 400;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					-ms-border-radius: 3px;
					-o-border-radius: 3px;
					border-radius: 3px;
					@include pos-transition(300ms);
					i{
						display: none;
					}
					&:hover{
						background:$main;
						border-color:$main;
						color:$light;
						@include pos-transition(300ms);		
					}
				}
			}
		}
	}
}

/* countdown */
.is-countdown {
	display:inline-block;
	width:100%;
	position:relative;
	margin:auto;
	margin-top:10px;
	.countdown-section {
		display: inline-block;
		text-align: center;	
		padding:10px 0;
		width: 60px;
		background: #f4f4f4;
		border: 1px solid #e5e5e5;
		border-radius: 3px;
		margin-right: 6px;
		@media (max-width:1199px) {
			width: 52px;
		}
		@media (max-width:991px) {
			width: 48px;
		}
		.countdown-amount {
			display: block;
			font-size: 24px;
			line-height: 23px;
			color: #222222;
			font-weight: 400;
			position: relative;
			@media (max-width: 1199px) {
				font-size:20px;
			}
		}
		.countdown-period {
			display: block;
			font-size: 13px;
			line-height: 15px;
			color: #999999;
			text-transform: capitalize;
		}
	}
} 

/* end countdown */
/* end module special-products */

/* module recommendedproducts */
.recommended-product {
	position:relative;
	margin-bottom:30px;
	.pos_title{
		h2{
			@media (max-width: 768px) {
				font-size: 20px;
			}
		}
	}
	.js-product-miniature { 
		border:none !important;
		box-shadow:none !important;
		padding:0;
		&:before{
			display: none;
		}
		.img_block {
			float:left;
			width:100px;
			margin-right:10px;
		}
		.product_desc {
			overflow:hidden;
			text-align:left;
			padding:0;
			@media (min-width:768px) and (max-width:991px) {
				width:100%;
				display:inline-block;
				padding-top:20px;
			}
			.hook-reviews{
				display: none;
			}
			.product_name{
				font-weight: 400;
			}
		}
	}
	.owl-dots{
		top: -37px;
		@media (max-width: 768px) {
			display: none;
		}
	}
}

/*end  module recommendedproducts */
/* module list-categories */
.poslistcategories {
	position:relative;
	.list-categories {
		position:relative;
		display:inline-block;
		width:100%;
		padding:20px 0;
		margin-bottom:20px;
		background:#ebebeb;
		min-height:200px; 
		&:last-child {
			margin-bottom:0;
		}
		&:hover {
			.name_categories {
			    color: $main;
				-webkit-transform: translateY(5px);
				-moz-transform: translateY(5px);
				-ms-transform: translateY(5px);
				-o-transform: translateY(5px);
				transform: translateY(5px);
				@include pos-transition(300ms);
			}
			.thumb-category {
				img {
					@include pos-transition(300ms);	
					@include pos-transform(1.05);	
				}
			}
		}
		.name_categories {
			margin:0 0 15px 0;
			font-size: 13px;
			line-height: 1;
			font-weight: 700;
			color: #444444;
			text-transform: uppercase;
			@include pos-transition(300ms);
		}

		.thumb-category {
			float:right;
			max-width:50%;
			overflow:hidden;
			@include pos-transition(300ms);	
			img {
				@include pos-transition(300ms);	
				@include pos-transform(1);	
			}
		}
		.desc-listcategoreis {
			overflow:hidden;
			padding:0 0 0 20px;
			.desc-content {
				color:#666666;
				font-size:13px;
				line-height:24px;
			}
			.see-more a {
				display: block;
				float: left;
				clear: both;
				margin-top: 20px;
				padding: 0 20px;
				background: #a8a8a8;
				color: $light;
				line-height:40px;
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 700;
				border-radius:5px;
				&:hover {
					background:$main;
				}
			}
		}
	}
}

/* end module list-categories */

/* blog */
.home_blog_post_area {
	position:relative;
	display:inline-block;
	width:100%;
	margin-bottom:40px;
	.blog_slider .item{
		.blog_post {
			display:inline-block;
			width:100%;
			background:$light;
			img{
				width: 100%;
			}
			.date-smart {
			    font-size: 13px;
				line-height: 22px;
				color: $light;
				background:#a8a8a8;
				display: inline-block;
				padding:0 10px;
			}
			.content-inner {
				overflow:hidden;
			}
			.post_title {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				color: #444444;
				text-transform: capitalize;
				position:relative;
				display:block;
				margin-bottom:10px;
				
			}
			.post_meta{
				overflow: hidden;
				span{
					color:#666666;
					font-size: 13px;
					line-height: 25px;
					text-transform:capitalize;
					display:inline-block;
					margin-right:20px;
					&:last-child {
						margin:0;
					}
				}
			}
			.post_description{
				font-size: 13px;
				font-weight: 400;
				color: #999999;
				margin: 0;
				display: inline-block;
				width: 100%;
				padding: 10px 0;
				line-height:18px;
			}
			a.read-more {
				display: inline-block;
				line-height: 18px;
				color: #999999;
				text-transform: capitalize;
				font-size: 13px;
				&:hover {
					color:$main;
				}
			}
		}
	}
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail {
    position: relative;
	margin-bottom:20px;
	overflow:hidden;
	&:hover {
		&:before {
			height: 100%;
			opacity: 0;
			-moz-opacity: 0;
			-khtml-opacity: 0;
			-webkit-opacity: 0;
			transition-duration: 1.3s;
			width: 100%;
		}
		&:after {
			height: 100%;
			opacity: 0;
			-moz-opacity: 0;
			-khtml-opacity: 0;
			-webkit-opacity: 0;
			transition-duration: 1.3s;
			width: 100%;
		}
	}
	&:before {
	    background: rgba(255, 255, 255, 0.41) none repeat scroll 0 0;
		content: "";
		height: 0;
		left: 0;
		margin: auto;
		opacity: 1;
		-moz-opacity: 1;
		-khtml-opacity: 1;
		-webkit-opacity: 1;
		position: absolute;
		top: 0;
		width: 0;
		pointer-events: none;
		z-index:1;
	}	
	&:after {
	    background: rgba(255, 255, 255, 0.41) none repeat scroll 0 0;
		bottom: 0;
		content: "";
		height: 0;
		opacity: 1;
		-moz-opacity: 1;
		-khtml-opacity: 1;
		-webkit-opacity: 1;
		position: absolute;
		right: 0;
		width: 0;
		pointer-events: none;
		z-index:1;
	}
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail .blog_mask {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: rgba(0,0,0,0.4);
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail .blog_mask .blog_mask_content {
    position: absolute;
    left: 110%;
    top: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail .blog_mask .blog_mask_content a {
    display: block;
    text-align: center;
    font-size: 70px;
    color: #fff;
	display:none;
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail:hover .blog_mask {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail:hover .blog_mask .blog_mask_content {
    left: 50%;
}
.kr_blog_post_area .blog_post {
    margin-bottom: 40px;
}
.kr_blog_post_area .blog_post .post_thumbnail img {
    margin:0;
}
.kr_blog_post_area .blog_post .post_content .post_title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.kr_blog_post_area .blog_post .post_content .post_meta > p {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    text-transform: capitalize;
    padding-right: 10px;
    margin-right: 10px;
}
.kr_blog_post_area .blog_post .post_content .read_more a {
    color: #999999;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
/* end blog */

/* module logo */
.pos_logo  {
	position:relative;
	padding: 55px 0;
	border-top: 1px solid #e5e5e5;
	margin-top: 70px;
	.pos_title {
		display:none;
	}
	.item-banklogo {
		overflow:hidden;
		&:hover {
			img {
				@include pos-transition(300ms);
				@include pos-transform(1.05);
			}
		}
		img {
			@include pos-transition(300ms);
			@include pos-transform(1);
		}
	}
}

/* end module logo */

/* module newletter */

.ft_newsletter {
	max-width: 500px;
    display: inline-block;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
	@media ( max-width:991px) {
		margin-bottom:20px;
	}
	@media ( max-width:480px) {
		padding: 0 20px;
	}
	i {
	    float: left;
		margin-right: 15px;
		width: 50px;
		height: 50px;
		line-height: 48px;
		display: block;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		border-radius: 100%;
		text-align: center;
		font-size: 20px;
		color: #333;
		&:hover {
			color: $main;
			@include pos-transition(300ms);
		}
	}
	.title-newsletter {
		overflow:hidden;
		margin-bottom:40px;
	}
	span.title{
		font-size: 60px;
		font-weight: 300;
		line-height: 60px;
		text-transform: uppercase;
	}
	h2 {
		font-size: 50px;
		font-weight: 700;
		color:#333333;
		text-transform: capitalize;
		line-height:50px;
		margin:0;
	}
	.desc{
	    font-size: 16px;
		color: #888888;
		margin: 25px 0 0;
		line-height: 25px;
	}
	form{
		position: relative;
		overflow: hidden;
		@media ( max-width:767px) {
			margin-bottom:20px;
		}
		.input-wrapper {
			input{
				height:50px;
				background:$light;
				border: 1px solid #e5e5e5;
				color:#666;
				padding: 10px 120px 10px 30px;
				display: inline-block;
				width:100%;
				font-size:14px;
				-webkit-border-radius: 25px;
				-moz-border-radius: 25px;
				-ms-border-radius: 25px;
				-o-border-radius: 25px;
				border-radius: 25px;
				@include placeholder {
					color:#666;
				}
			}
		}
		button.btn{
			position: absolute;
		    top: 0;
			right: 0;
			padding: 0;
			width: auto;
			display: block;
			line-height: 30px;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			background: transparent;
			border: 0;
			border-radius: 0 5px 5px 0; 
			box-shadow:none;
			@include pos-transition(300ms);
			&:hover{
				i{
					color: $main;
				}
			}
		}
		.condition, .alert{
			margin: 10px 0 0;
			font-size: 12px;
			line-height: 24px;
			color: #bcbcbc;
			background: none;
			border: 0;
			padding: 0;
			&.alert-danger{
				color: $main;
			}
			&.alert-success{
				color: $main;
			}
		}
	}
}

/* end module newletter */
.social_follow {
	position:relative;
	text-align:left;
	margin-top: 40px;
	@media (max-width: 767px) {
		text-align:left;
	}
	li {
		display: inline-block;
		margin: 0 10px 0 0;
		background-image: none !important;
		padding: 0;
		width: 40px;
		height: 40px;
		text-align: center;
		background: #393939;
		line-height: 40px;
		border-radius: 100%;
		&:last-child {
			margin:0;
		}
		&:hover {
			background:$main;
			@include pos-transition(300ms);
		}
		@media (max-width: 768px) {
			margin: 0 2px 0 0;
		}
		a {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			color: $light;
			font-size: 0;
			padding: 0;
			line-height: 30px;
			border: 0;
			z-index: 1;
			@include pos-transition(300ms);
			span {
				
			}
			&:hover {
				color: #fff;
				@include pos-transition(300ms);
			}
			&:before {
				padding:0 !important;
				font-size:18px;
				display: inline-block;
				font-family: "Font Awesome\ 5 Brands";
				font-weight: normal;
				vertical-align:middle;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				@include pos-transition(300ms);
			}
			
		}
		&.facebook {
			a {
				
				&:before {
					content: "\f39e";
				}
			}
		}
		&.twitter {
			a {
			
				&:before {
					content: "\f099";
				}
			}
		}
		&.rss {
			a {
		
				&:before {
					content: "\f09e";
				}
			}
		}
		&.youtube {
			a {

				&:before {
					content: "\f167";
				}
			}
		}
		&.googleplus {
			a {

				&:before {
					content: "\f0d5";
				}
			}
		}
		&.pinterest {
			a {
		
				&:before {
					content: "\f0d2";
				}
			}
		}
		&.vimeo {
			a {
			
				&:before {
					content: "\f194";
				}
			}
		}
		&.instagram {
			a {
	
				&:before {
					content: "\f16d";
				}
			}
		}
	}
}
/* end module social */

/* module advertising */
.advertising {
	position: relative;
    margin-bottom: 30px;
    text-align: center;
	overflow:hidden;
	a {
		img {
			width: 100%;
			height:auto;
		}
		&:before {
			background: rgba(255, 255, 255, 0.2);
			bottom: 0;
			content: "";
			left: 50%;
			position: absolute;
			right: 51%;
			top: 0;
			opacity: 1;
			
		
		}
		&:after {
			    background: rgba(255, 255, 255, 0.2);
				bottom: 50%;
				content: "";
				left: 0;
				position: absolute;
				right: 0;
				top: 50%;
				opacity: 1;
			
		}
	}
	&:hover a:before{
			left: 0;
			right: 0;
			opacity: 0;
			@include pos-transition(900ms);
		}
	&:hover  a:after{
		top: 0;
		bottom: 0;
		opacity: 0;
		@include pos-transition(900ms);
	}
}
/* end module advertising */
/* module tags */
	#tags_block {
		background:#8f8f8f;
		padding:40px 0 25px 0;
		h2 {
			font-size: 16px;
			line-height: 1;
			font-weight: 700;
			color: $light;
			margin:0;
			text-transform: uppercase;
			float:left;
			margin-right:30px;
		}
		.tags_block {
			overflow:hidden;
			@media ( max-width:991px) {
				display:inline-block;
				width:100%;
				margin-top:15px;
			}
			
			li {
				border-left: 2px solid $light ;
				line-height:1;
				margin-bottom:15px;
				float:left;
				list-style: none;
				&:first-child {
					border-left:none;
					a {
						margin-left:0;
					}
				}
				a {
					display: block;
					float: left;
					margin:0 15px;			
					font-size: 12px;
					line-height: 1;
					color:$light;
					font-weight:600;
					text-transform:capitalize;	
					border-bottom:1px solid transparent;		
					&:hover {
						border-color:#cccccc;
					}
				}
			}
		}
	}

	
/* end module tags */
/* module view product */
.view-products {
	position:relative;
	margin-bottom:50px;
	.owl-nav {
		right: 0px;
	}
	.pos_title {
		display: block;
		border-bottom: $main_border;
		padding-bottom: 15px;
		margin:0;
		&:before {
			display:none;
		}
	}
	.js-product-miniature {
		border:none;
		border-bottom:$main_border !important;		
		padding:20px 0;
		margin: 0;
		.img_block {
			float:left;
			width:98px;
			margin-right:15px;
			
		}
		.product_desc {
			padding:0;
			@media ( min-width:768px) and ( max-width:991px) {
				display:inline-block;
				width:100%;
				padding-top:15px;
			}
			.product-price-and-shipping {
				min-height:auto;
			}
			.product_name {
				color: #3a3939;
				font-weight:400;
				&:hover {
					color:$main;
				}
			}
		}
	}
}

/* end module view product */
/* module testimonials */
.testimonials_container {
    position: relative;
	margin-bottom:40px;
	.item-testimonials {
		.item {
			position:relative;
			margin-bottom:40px;
			&:last-child {
				margin:0;
			}
			.des_testimonial {
				border:$main_border;
				padding:15px;
				position:relative;
				background:$light;
				margin-bottom: 15px;
				&:before {
					content:"";
					background: url(../img/icon_testi.png) no-repeat;
					width:10px;
					height:10px;
					display:inline-block;
					position:absolute;
					left:20px;
					bottom:-10px;
					
				}
				p {
					color:#999999;
					margin:0;
					line-height:18px;
				}
			}	
			.content_author {
				img {
					float:left;
					margin:0;
				}	
				.content_test {
					overflow:hidden;
					padding:10px 15px;
					.des_namepost {
						font-size:14px;
						color:$main;
						line-height:25px;
						font-weight:700;
						text-transform:capitalize;
						margin:0;
					}
					.des_email {
						font-size:13px;
						color: #444444;
						line-height:20px;
						margin:0;
						&:hover {
							color:$main;
						}
					}
				}
				
			}
		}
	}
	
}
/* end module testimonials */
/* item product */
.pos_title {
	position:relative;
	display:block;
	border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
	h2 {
		margin: 0;
		position: relative;
		z-index: 2;
		color: #333333;
		font-size: 24px;
		font-weight: 700;
		line-height: 1;
		display:inline-block;
		padding-bottom:15px;
		text-transform:uppercase;
	}
}
.lastActiveItem{
	.js-product-miniature{
		&:before{
			display: none;
		}
	}
}
.js-product-miniature{
	position:relative;
	background:$light;
	display:inline-block;
	width:100%;
	border:1px solid transparent;
	text-align:center;
	margin-top: 30px;
	@include pos-transition(300ms);
	&:before{
		content: "";
		width: 1px;
		height: 80%;
		position: absolute;
		top: 0;
		right: -15px;
		background: #e5e5e5;
	}
	&:last-child {
		margin-bottom:0;
	}
	.img_block {
		position:relative;
		img {
			width:100%;
			margin:0;
			@include pos-transition(300ms);
		}
		
		.quick-view {
			position:absolute;
			top:50%;
			left:50%;
			-moz-transform: translateX(-50%) translateY(-50%);
			-webkit-transform: translateX(-50%) translateY(-50%);
			-o-transform: translateX(-50%) translateY(-50%);
			-ms-transform:translateX(-50%) translateY(-50%);
			transform:translateX(-50%) translateY(-50%);
			opacity:0;
			text-align:center;
			max-height:40px;
			@include pos-transition(300ms);
			a.quick_view {
				display: inline-block;
				background:$main;
				color: #fff;
				padding: 0;
				line-height:40px;
				height:40px;
				width:40px;
				font-size:0;
				text-transform: capitalize;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				-ms-border-radius: 3px;
				-o-border-radius: 3px;
				border-radius: 3px;
				i {
					font-size:16px;
					display:block;
					line-height:40px;
				}
				&:hover {
					background: #333;
					@include pos-transition(300ms);
				}
			}
			
		}
		
	}
	&:hover 	{
		.quick-view {
			opacity:1;
			@include pos-transition(300ms);
		}
		.product_desc{
			.add-to-links{
				li{
					opacity:1;
					@include pos-transition(300ms);
				}
			}
		}
		
	}
	.product_desc {
		padding:20px 0;
		background:$light;
		overflow:hidden;
		h3 {
			font-weight:400;
			margin: 0;
			line-height: 1;
		}
		.product_name {
			line-height: 1.4rem;
			display: block;
			overflow: hidden;
			color: #666666;
			font-size: 16px;
			margin-bottom:12px;
			text-transform: capitalize;
			font-weight: 600;
			&:hover {
				color:$main;
			}
			
		}
		.manufacturer{
			a{
				font-size: 12px;
				color: #999999;
				text-transform: uppercase;
				letter-spacing: 0.2em;
				&:hover{
					color: $main;
				}
			}
		}
		.add-to-links{
			margin-top:20px;
			li{
				cursor: pointer;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				font-size: 16px;
				text-align: center;
				border: 1px solid #e8e8e8;
				color: #666;
				opacity: 0;
				vertical-align: top;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				-ms-border-radius: 3px;
				-o-border-radius: 3px;
				border-radius: 3px;
				@include pos-transition(300ms);
				i{
					line-height: 40px;
				}
				&:hover{
					border-color: $main;
					color: $main;
					a{
						color: $main;
					}
				}
			}
		}
		li.cart {
			width: auto;
			border: none;
			opacity: 1;
			margin: 0 10px;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			-ms-border-radius: 0;
			-o-border-radius: 0;
			border-radius: 0;
			button.ajax_add_to_cart_button,span.ajax_add_to_cart_button  {
				display: inline-block;
				border: 1px solid #e8e8e8;
				color: #666;
				padding: 0 30px;
				line-height: 41px;
				background: transparent;
				height:40px;
				text-transform: uppercase;
				cursor:pointer;
				font-size:13px;
				font-weight:400;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				-ms-border-radius: 3px;
				-o-border-radius: 3px;
				border-radius: 3px;
				@include pos-transition(300ms);
				@media (max-width: 768px) {
					padding: 0 15px;
				}
				@media (max-width: 480px) {
					padding: 0 10px;
				}
				i {
					font-size:14px;
					margin-right:5px;
					display:none;
				}
				&.disabled {
					opacity:0.5;
				}
				&:hover{
					background:$main;
					border-color:$main;
					color:$light;
					@include pos-transition(300ms);		
				}
			}
		} 
		.hook-reviews {
			display:block;
			line-height:1;
			margin-bottom:15px;
			@include pos-transition(300ms);	
			.comments_note {
				direction:ltr !important;
			}
			.star_content {
			    display: inline-block;
				vertical-align: bottom;
				
			}
			.nb-comments {
				font-size:12px;
				display:none;
			}
		}
		.product-desc {
			display:none;
			padding: 15px 0;
			font-size: 13px;
			color: #666666;
			line-height: 25px;
			margin:0;
			p{
				font-size: 13px;
				color: #666666;
				line-height: 25px;
				margin:0;
			}
		}
		
		.variant-links {
			margin-top:15px;
			margin-top: 15px;
			background: transparent;
			padding: 0;
			min-height: auto;
			text-align: left;
			display:none;
			a {
				margin:0 5px 0 0;
				margin: 0 5px 0 0;
				border-radius: 100%;
				width: 15px;
				height: 15px;
			}
		}
	
		
	}
}

.js-product-miniature .product-price-and-shipping {
	line-height:1;
	.sr-only {
		display:none;
	}
	.price {
		font-size: 20px;
		font-weight: 700;
		color: #ea3a3c;
	}
	.regular-price {
		font-size: 16px;
		color: #999;
		font-weight:400;
		text-decoration: line-through;
		margin-right:5px;
	}
	.discount-percentage {
		display: inline-block;
		position:absolute;
		top:10px;
		right:5px;
		background: $main;
		color:$light;
		border-radius: 3px;
		padding:0 5px;
		min-width:55px;
		text-align:center;
		line-height:28px;
		height:25px;
		font-size:15px;
		font-weight: 400;
	}
}
.product-flag  {
	.new,
	.on-sale,.pack{
		display: inline-block;
		position: absolute;
		top: 10px !important;
		left: 5px;
		background: #0084c5;
		color: white;
		border-radius: 3px;
		padding: 0 5px;
		min-width: 55px;
		text-align: center;
		line-height: 28px;
		height: 25px;
		font-size: 15px;
		font-weight: 400;
	}
}
.product-flag .pack {
	z-index:2
}
.product-flag .on-sale{
	top:35px !important;
	&:before {
		background: #f8484a;
	}
	span {
		background: #f8484a;
	}
}
.product-flag  .discount {
	display:none;
}
.owl-dots {
	position:absolute;
	right:15px;
	top:-50px;
	@media (max-width:767px){
		display: none;
	}
	.owl-dot  {
		display:inline-block;
		margin:0 4px;
		&:last-child {
			margin-right:0;
		}
		span {
			display:inline-block;
			width:9px;
			height:9px;
			border-radius:100%;
			background: transparent;
			border: 1px solid $main;
			@include pos-transition(300ms);
			&:hover {
				background: $main;
				@include pos-transition(300ms);
			}
		}
		&.active {
			span{
				background: $main;
				width: 26px;
				border-radius: 25px;
				@include pos-transition(300ms);
			}
		}
	}
}
.owl-nav {
	> div {
		position:absolute;
		top: 50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		display:inline-block;
		font-size:0;
		cursor:pointer;
		text-align:center;
		color: #333333;
		border:none;
		z-index:1;
		opacity:0;
		@include pos-transition(300ms);	
		&:before {
			font-size: 24px;
			font-weight: 900;
			font-family: "Font Awesome 5 Free";
			display:block;
		}
		&:hover {
			@include pos-transition(300ms);	
			color: $main;
		}
		@include pos-transition(300ms);	
		
		&.owl-prev {
			left:15px;
			border-radius: 0 5px 5px 0;
			&:before {
				content: "\f053";
			}
		}
		&.owl-next{
			right:15px;
			border-radius: 5px 0 0 5px;
			&:before {
				content: "\f054";
			}
		}
		@media (max-width:767px){
			display:none;
		}
	}
} 
.pos_content {
	position:relative;
	&:hover {
		.owl-nav {
			> div {
				opacity:1;
			}
		}
	}
	.js-product-miniature ,.thumb-category,.item_logo,.thumb-container{
		img {
			display:none;
		}
	}
	.owl-item {
		.js-product-miniature,.thumb-category,.item_logo,.thumb-container {
			img {
				display:block;
			}
		}
	}
}

.categoryproducts,.product-accessories,.poslistcateproduct,.tab-category-container-slider,.product-tabs-container-slider ,.pos-special-products ,.pos_random_product,.poslistcategories {
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
    width: 100%;
	background:$light;
}
.tab-category-container-slider,.categoryproducts,.product-accessories{
	.owl-dots {
		position: absolute;
		right: 15px;
		top: -40px;
	}
	.owl-nav {
		> div {
			&.owl-prev {
				left:0;
			}
			&.owl-next{
				right:0;
			}
		}
	}

}
.pos-featured-products, .pos-bestsellers-product, .pos_new_product{
	//margin-top: 80px;
	@media (max-width: 767px) {
		margin-top: 40px;
	}
	.js-product-miniature{
		padding: 10px 0;
		margin-top: 20px;
		margin-bottom: 0;
		&:last-child{
			border-bottom: none;
			padding-bottom: 0;
		}
		&:before{
			display: none;
		}
		.img_block{
			width: 80px;
			display: inline-block;
			float: left;
			margin-right: 22px;
		}
		.product_desc{
			display: inline-block;
			text-align: left;
			padding: 0;
			vertical-align: top;
			float: left;
			@media (max-width:1199px) {
				width: 60%;
			}
			@media (max-width: 480px) {
				width: 46%;
			}
			.product-price-and-shipping{
				text-align: left;
				margin-top: 17px;
			}
			//.hook-reviews{
			//	display: none;
			//}
			.product_name{
				font-weight: 400;
			}
		}
	}
	.owl-dots{
		top: -36px;
	}
}

/* end item product */
/* static */
 @-webkit-keyframes hvr-icon-buzz-out{
    10%{
        -webkit-transform:translateX(3px) rotate(2deg);
        transform:translateX(3px) rotate(2deg)
    }
    20%{
        -webkit-transform:translateX(-3px) rotate(-2deg);
        transform:translateX(-3px) rotate(-2deg)
    }
    30%{
        -webkit-transform:translateX(3px) rotate(2deg);
        transform:translateX(3px) rotate(2deg)
    }
    40%{
        -webkit-transform:translateX(-3px) rotate(-2deg);
        transform:translateX(-3px) rotate(-2deg)
    }
    50%{
        -webkit-transform:translateX(2px) rotate(1deg);
        transform:translateX(2px) rotate(1deg)
    }
    60%{
        -webkit-transform:translateX(-2px) rotate(-1deg);
        transform:translateX(-2px) rotate(-1deg)
    }
    70%{
        -webkit-transform:translateX(2px) rotate(1deg);
        transform:translateX(2px) rotate(1deg)
    }
    80%{
        -webkit-transform:translateX(-2px) rotate(-1deg);
        transform:translateX(-2px) rotate(-1deg)
    }
    90%{
        -webkit-transform:translateX(1px) rotate(0);
        transform:translateX(1px) rotate(0)
    }
    100%{
        -webkit-transform:translateX(-1px) rotate(0);
        transform:translateX(-1px) rotate(0)
    }
}
.banner-nav {
	position:relative;
	.close_banner {
		position:absolute;
		top: 50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		right:30px;
		color:$light;
		cursor:pointer;
		@media (max-width: 480px) {
			right: 15px;
			i {
			    font-size: 16px
			}
		}
	}
}
.welcome {
	font-size:13px;
	color:#666666;
	line-height:41px;
	float:left;
	@media (max-width: 767px) {
		display:inline-block;
		width:100%;
		float:none;
	}
	span {
		color:$main;
		font-weight:700;
		text-transform:capitalize;
	}
}
.banner-slide{
	.col{
		padding: 0;
	}
}
.banner-top{
	margin-top: 30px;
	.banner-warper{
		border: 2px solid #e5e5e5;
		border-radius: 3px;
		text-align: center;
		padding: 10px 0;
		background: #f4f4f4;
		@media (max-width: 767px) {
			margin-bottom: 20px;
		}
		&:hover{
			background: none;
			@include pos-transition(300ms);	
		}
		.icon{
			font-size: 30px;
			color: $main;
		}
		.text{
			margin-top: 12px;
			h3{
				font-size: 20px;
				color: #333333;
				font-weight: normal;
				@media (max-width: 768px) {
					font-size: 15px;
				}
			}
			p{
				color: #666;
				font-size: 13px;
				@media (max-width: 768px) {
					font-size: 11px;
				}
			}
		}
	}
}
.banner-center{
	.col{
		padding: 0;
	}
}
.banner-center-bottom{
	position: relative;
	&:hover{
		.banner-cms{
			img{
				-webkit-animation: zoomIn 800ms ease-in-out;
				-moz-animation: zoomIn 800ms ease-in-out;
				-ms-animation: zoomIn 800ms ease-in-out;
				animation: zoomIn 800ms ease-in-out;
			}
		}
	}
	.banner-cms{
		position: absolute;
		z-index: 9;
		top: 38px;
		right: 75px;
		@media (min-width: 1600px) {
			top: 138px;
		}
		@media (max-width:768px) {
			right: -25%;
		}
		@media (max-width:640px) {
			right: -40%;
		}
		@media (max-width:480px) {
			display: none;
		}
		img{
			@media (max-width:1199px) {
				width: 70%;
				margin: 0;
			}
			@media (max-width:991px) {
				width: 65%;
			}
			@media (max-width:768px) {
				width: 50%;
			}
			@media (max-width:640px) {
				width: 38%;
			}
		}
	}
}
.banner-bottom{
	position: relative;
	&:hover{
		.banner-cms{
			img{
				-webkit-animation: bounceIn 800ms ease-in-out;
				-moz-animation: bounceIn 800ms ease-in-out;
				-ms-animation: bounceIn 800ms ease-in-out;
				animation: bounceIn 800ms ease-in-out;
			}
		}
	}
	.banner-cms{
		position: absolute;
		z-index: 9;
		top: 38px;
		left: 85px;
		@media (min-width: 1600px) {
			top: 160px;
			left: 20%;
		}
		@media (max-width:480px) {
			display: none;
		}
		img{
			@media (max-width:1199px) {
				width: 66%;
				margin: 0;
			}
			@media (max-width:768px) {
				width: 50%;
			}
			@media (max-width:640px) {
				width: 36%;
			}
		}
	}
}
.home-banner {
	position:relative;
	.banner-box {
		position:relative;
		overflow:hidden;
		&:before {
			background: rgba(255, 255, 255, 0.41) none repeat scroll 0 0;
			content: "";
			height: 0;
			left: 0;
			margin: auto;
			opacity: 1;
			-moz-opacity: 1;
			-khtml-opacity: 1;
			-webkit-opacity: 1;
			position: absolute;
			top: 0;
			width: 0;
			pointer-events: none;
		}
		&:after {
			background: rgba(255, 255, 255, 0.41) none repeat scroll 0 0;
			bottom: 0;
			content: "";
			height: 0;
			opacity: 1;
			-moz-opacity: 1;
			-khtml-opacity: 1;
			-webkit-opacity: 1;
			position: absolute;
			right: 0;
			width: 0;
			pointer-events: none;
		}
		&:hover {
			&:before {
			    height: 100%;
				opacity: 0;
				-moz-opacity: 0;
				-khtml-opacity: 0;
				-webkit-opacity: 0;
				transition-duration: 1.3s;
				width: 100%;
			}		
			&:after {
				height: 100%;
				opacity: 0;
				-moz-opacity: 0;
				-khtml-opacity: 0;
				-webkit-opacity: 0;
				transition-duration: 1.3s;
				width: 100%;
			}
		}
		img {
			width:100%;
			@include pos-transition(1000ms);
			&.item_in {
				position:absolute;
				top:0;
				left:0;
			}
		}
	}
}
.static_cms {
	position:relative;
	background:#666666;
	padding:10px 0;
	@media (max-width: 991px) {
		padding:10px 0 0 0;
		
	}
	.row {
		margin:0 -5px;
		.col-cms {
			padding:0 5px;
		}
		
	}
	.box_cms {
		background:$light;
		padding:5px 30px;
		@media (min-width: 992px) and (max-width: 1199px) {
			padding:5px 10px;
		}	
		@media  (max-width: 991px) {
			margin-bottom:10px;
			
		}
		border-radius:50px;
		display: inline-block;
		width: 100%;
		&:hover {
			img {
				-webkit-animation-name: hvr-icon-buzz-out;
				animation-name: hvr-icon-buzz-out;
				-webkit-animation-duration: 0.75s;
				animation-duration: 0.75s;
				-webkit-animation-timing-function: linear;
				animation-timing-function: linear;
				-webkit-animation-iteration-count: 1;
				animation-iteration-count: 1;
			}
		}
		img {
			float:left;
			margin-right:15px;
		}
		.txt_cms {
			overflow:hidden;
			padding:7px 0;
			h2 {
				line-height:20px;
				color:#666666;
				font-size:15px;
				text-transform:capitalize;
				margin:0;
			}
		}
	}
}
/* end static */
/* footer */
#footer {
	padding:0;
	color: #666666;
	.footer-container {
		margin: 0;
		padding: 0;
		overflow: visible;
		box-shadow: none;
		background: #222;
		.footer-top {
			position: relative;
			text-align: center;
			background: url(../img/bg-newletter.jpg) no-repeat center;
			min-height: 725px;
			@media (max-width:1199px) {
				min-height: 425px;
			}
		}
		.footer-middle {
			padding:45px 0;
			.desc{
				font-size: 14px;
				color: #bbbbbb;
				line-height: 25px;
				padding-bottom: 39px;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					height: 2px;
					width: 45px;
					background: $main;
				}
			}
		}
		.footer-bottom {
		    background: #333;
			padding:25px 0;
			@media (max-width:767px) {
				text-align: center;
			}
			.copyright  {
				line-height:30px;
				color:#bbbbbb;
				a {
					color: $main;
					&:hover {
						color:$main;
					}
				}
			}
			.payment-block{
				float: right;
				img{
					float: right;
					@media (max-width:767px) {
						float: none;
						margin: 20px 0 0;
						display: inline-block;
					}
				}
			}
		}
		.logo {
			margin-bottom:20px;
			img {
				margin:0;
			}
		}
		.footer-aboutus {
			margin-top: 25px;
			.add {
				position:relative;
				overflow:visible;
				color:#bbb;
				line-height:34px;
				span.txt{
					color: #fff;
				}
				&:before {
					display: inline-block;
					color: #fff;
					font-family: "Font Awesome 5 Free";
					font-size:16px;
					margin-right:10px;
					vertical-align:-2px;
					font-weight:900; 
				}
				&.address:before {
					content: "\f015"; 
				}
				&.phone:before {
					content: "\f095";
				}
				&.email:before {
					content: "\f0e0";
				}
				&.time:before {
					content: "\f017";
				}
			}
			
			a {
				color:#bbb;
				&:hover {
					color:$main;
				}
			}
		}
		.banner-nav{
			margin-bottom: 45px;
			@media (max-width: 767px) {
				margin-top: 30px;
			}
		}
		.footer_block{
			margin-bottom:0;
			@media (max-width: 767px) {
				margin-bottom:15px;
				.title {
					padding:0;
					border:none;
				}
			}
			h3{
				font-size: 18px;
				font-weight: 700;
				color: #fff;
				margin: 0 0 30px;
				padding: 0 0 20px 0;
				position: relative;
				//cursor: pointer;
				text-transform: uppercase;
				&:before{
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					height: 2px;
					width: 45px;
					background: $main;
				}
				@media (max-width: 767px) {
					margin:0;
				}
			}
			.navbar-toggler {
				position: absolute;
				right: 15px;
				top: 0;
				width:auto;
				height:auto;
				color:#fff;
			}
			.footer_list    {
				margin-bottom:0;
				background:none;
				li {
					display:block;
					margin: 0;
					line-height:34px;
					border:0;
					padding:0;
					font-weight:400; 
					a {
						font-size: 14px;
						line-height: 34px;
						display: block;
						position: relative;
						color:#bbb;
						@include pos-transition(300ms);
						&:before {
							content: "\f105";
							font-family: 'Font Awesome 5 Pro';
							font-size: 14px;
							display:inline-block;
							margin-right:5px;
							font-weight:300;
							color:#bbbbbb;
						}
						&:hover {
							color:$main;
							padding-left:10px;
							&:before {
								color:$main;
							}
							@include pos-transition(300ms);
						}
					
					}
				}
			}

			
		}
		
	}
}
/* end footer */

/* category page */
@media (max-width: 767px) {
	#left-column ,#content-wrapper,	#right-column  {
		width: 100%;
	}
}
.name_category {
	text-align:center;	
	margin-bottom:40px;
	display:inline-block;
	width:100%;
	h2 {
		display: inline-block;
		position: relative;
		font-size: 30px;
		font-weight: 700;
		color: white;
		margin:0;
		line-height:1;
		text-transform:uppercase;
	}
}
#js-product-list-top {
	ul.display  {
		float:left;
		margin-right:50px;
		position:relative;
		z-index:1;
		@media (min-width: 768px) and (max-width: 991px) {
			margin-right:10px;
		}
		@media (max-width: 767px) {
			margin-bottom:10px;
		}
		li {
			float:left;
			height:40px;
			width:40px;
			border: 1px solid $main;
			line-height:45px;
			margin-right:10px;
			cursor:pointer;
		    color: $main;
			text-align:center;
			border-radius: 3px;
			background:transparent;
			@include pos-transition(300ms);
			&:hover, &.selected{
				color:#fff;
				background: $main;
				@include pos-transition(300ms);
			}
			i {
				font-size:16px;
			}
		}
	}
}

.quickview .modal-content {
    background:$light;
}
#js-product-list {
	.product_content {
		position:relative;
		background:$light;
		&.list {
			.js-product-miniature {
				border-bottom: 1px solid #e5e5e5;
				padding-bottom: 20px;
				&:before{
					display: none;
				}
				@media (min-width: 480px) {
					.row {
						margin:0;
					}
				}
				.img_block  {
					padding:0;
					overflow:hidden;
				}
				.product_desc  {
					padding-left:30px;
					padding-top:10px;
					text-align:left;
					@media (max-width: 479px) {
						padding-top:30px; 
						padding-left:0;
					}
					.product_name {
					    height: auto;
						line-height: 24px;
						font-size: 17px;
						margin-bottom:10px;
						padding-right:70px;
						&:hover {
							color:$main;
						}
					}
					.product-price-and-shipping{
						margin-top: 20px;
					}
					.add-to-links{
						li{
							opacity: 1;
							border-color: $main;
							color: $main;
							a{
								color: $main;
							}
							&:hover{
								background-color: $main;
								color: #fff;
								a{
									color: #fff;
								}
							}
						}
						li.cart{
							button.ajax_add_to_cart_button, span.ajax_add_to_cart_button{
								border-color: $main;
								color: $main;
								@media (max-width: 1199px) {
									padding: 0 16px;
								}
								&:hover{
									color: #fff;
								}
							}
						}
					}
					.product-desc {
						display:block;
						p {
							font-size: 14px;
							color: #666666;
							line-height: 25px;
							padding: 0;		
						}
					}
					.variant-links {
						margin-top:30px;
					}
					
					
		
				}
				@media (max-width: 479px) {
					.img_block ,.product_desc {
						width:100%;
					}
					
				}
			}
		}
		&.grid {
			.item-product {
				@media (min-width: 480px) and (max-width: 543px) {
					width:50%;
				}
			}
			.item-product:nth-child(3n)  {
				.js-product-miniature:before{
					display: none;
				}
			}
			@media (min-width:1200px) {
				.item-product:nth-child(3n+1)  {
					clear:both;
				}
			}
			@media (min-width:480px) and  (max-width: 991px) {
				.item-product:nth-child(2n+1)  {
					clear:both;
				}
			}
			@media (max-width: 479px) {
				.item-product  {
					clear:both;
				}
			}
			.js-product-miniature{
				.product_desc{
					h3{
						@media (max-width:1199px) {
							min-height: 45px;
						}
					}
					li.cart{
						@media (max-width: 500px) {
							margin: 0 4px;
						}
						button.ajax_add_to_cart_button, span.ajax_add_to_cart_button{
							@media (max-width: 1199px) {
								padding: 0 16px;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width:480px) and (max-width:543px) {
	#js-product-list .product_content .item-product.grid  {
		float:left;
		width:50%;
	}
}

.breadcrumb_container{
	overflow: hidden;
	margin:0;
	font-size:12px;
	.breadcrumb-inner {
		background: transparent;
		padding-bottom:15px;
		border-bottom:$main_border;
		margin: 0 0 30px 0;
		background:$light;
		ol {
			padding-left: 0;
			margin-bottom: 0;
			li {
				display: inline;
				&:after{
					content: "\f105";
					font-family: "Font Awesome 5 Free";
					color: #999; 
					margin: 0.3125em;
					font-size:12px;
					font-weight:900;
				}
				&:last-child {
					a ,span{
						color:$main;
					}
					&:after{
						content: "";
					}
				}
				a{
					display: inline-block;
					position: relative; 
					color: #666;
					font-size: 14px;
					line-height:25px;
					&:hover{
						color: $main;
					}
				}
			}
		}
	}
	.breadcrumb-inner[data-depth="1"] {
		display: none;
	}
}

.block-category {
	padding:0;
	min-height:auto;
	margin:0;
	h1.h1 {
		font-weight:700;
		color:#333333;
		font-size:24px;
		line-height:24px;
		padding:15px 0;
	}
	.category-cover {
		position:static;
		img {
			width:auto;
			height:auto;
		}
	}
	&.card {
		box-shadow: none;
		border:0;
	}

}

#products img, .featured-products img, .product-accessories img{
	margin: 0;
}
.block-categories, #search_filters{
	box-shadow: none;
    background: transparent;
    padding:0;
    margin-bottom: 30px;
}
.block-categories{
	a.h6{
		font-size: 24px;
		line-height: 25px;
		font-weight: 700;
		color: #333333;
		text-transform: uppercase !important;
		border-bottom: $main_border;
		padding:0 0 20px;
		margin-bottom: 20px;
		position: relative;
		display:block;
		&:before{
			content: "\f107";
			font-family: "Font Awesome 5 Free";
			color: #333333;
			float: right;
			font-size: 14px;
			font-weight: 900;
			line-height: 20px;
		}
	}
	.category-sub-menu {
		margin:0;
		background:$light;
		li {
			display:inline-block;
			width:100%;
			a{
				color: #333333;
				&:hover{
					color: $main;
				}
			}
		}
	}
	.category-sub-menu li[data-depth="0"] > a{
		font-weight:400;
		color: #666666;
		display: block;
		font-size: 14px;
		text-transform:capitalize;
		line-height:30px;
		margin: 0;
		padding: 0;
		border: none;
		&:before{
			content: "\f111";
			display: block;
			line-height: 27px;
			float: left;
			padding: 0 7px 0 1px;
			font-family: "Font Awesome 5 Free";
			font-size: 13px;
			font-weight: 400;
		}
		&:hover {
			color:$main;
		}
	}
	.category-sub-menu li[data-depth="1"] {
		margin:0;
	}
	.category-sub-menu li:last-child a{
		border-bottom:0;
	}
	.category-sub-menu .category-sub-link {
	    font-size: 12px;
		line-height:30px;
		margin: 0;
		padding: 0 10px;
		display: block;
		
	}
	.collapse-icons {
		top: 5px;
		right: 0;
		height: auto;
		width: auto;
		i {
			font-size:18px;
			&:hover {
				color:$main !important;
			}
		}
	}


}

#search_filters_wrapper #search_filters h4{
	font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: #333333;
    text-transform: uppercase !important;
    border-bottom: 1px solid #d9d9d9;
    padding: 0 0 20px;
    margin-bottom: 20px;
    position: relative;
    display: block;
	&:before {
		content: "";
		display: block;
		float: right;
		content: "\f107";
		font-family: "Font Awesome 5 Free";
		font-size: 14px;
		font-weight:700;
		line-height: 15px;
	}
	
}
.facet-content {
	margin-top:15px;
}
#search_filters .facet {
	.facet-title{
	    display: block;
		clear: both;
		color: #333;
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 10px;
		line-height: 24px;
		text-transform:uppercase;
	}
	.collapse{
		margin: 0;
		background:$light;
		li {
			line-height:25px;
		}
	}
	.custom-checkbox input[type="checkbox"] + span{
		margin: -3px 3px 0 0;
		width: 15px;
		height: 15px;
		border: 1px #dadada solid;
		&.color{
			border: 0;
		}
	}
	.facet-label {
		margin: 5px 0;
		a{
		    color: #666666;
			font-size: 14px;
			margin-top:0;
			&:hover{
				color: $main;
			}
		}
	}
}
/* Drop-down list */
.facet-dropdown {
    color: #666666;
    background:$light;
    border:none;
    box-shadow: none;
}
.facet-dropdown .select-title {
	border:$main_border;
    color: #666666;
	padding: 10px 15px;
    border-radius: 5px;
    background: #ffffff;
    background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
}
.facet-dropdown.open>.select-title {
    border:$main_border;
    background: #ffffff;
    background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
}
.facet-dropdown .dropdown-menu {
	border:$main_border;
     background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
    background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
    box-shadow: none;
}
.facet-dropdown .select-list {
    display: block;
    color: #666666;
    font-size:13px;
	background:transparent;
}
/* end Drop-down list */
/* page list */
.pagination .page-list {
    box-shadow: none;
	padding: 0;
    text-align: right !important;
}
.pagination{
	font-size: 14px;
    font-weight: 400;
   	padding-bottom:20px;
	border-bottom:$main_border;
	margin:30px 0;
	line-height: 40px;
    color: #666666;
	.previous,.next {
	   float:none;
	}
	a{
		font-weight: 400;
		color: $light;
		padding: 0 15px;
		height:40px;
		line-height:40px;
		background:#a8a8a8;
		border-radius:5px;
		font-size:14px;
		display:inline-block;
		i {
			font-size:14px;
		}
		&:hover{
			background: $main;
		}
	}
	.disabled{
		color:$light;
	}
	.current a{
		background: $main;
		font-size:14px;
		color:$light;
	}
}

/*end page list */
@media (max-width: 767px) {
	#category #left-column #search_filters .facet .navbar-toggler {
		padding: 0.625rem 3rem 0 0;
	}
}
.products-selection .filter-button .btn-secondary, .products-selection .filter-button .btn-tertiary {
    box-shadow: none;
    padding: 4px 15px;
    border-radius: 4px;
}
.products-selection .total-products {
    padding-top:0;
}
.products-selection{
	border-bottom:$main_border;
	margin-top:30px;
	margin-bottom:0;
	padding-bottom:30px;
	display:inline-block;
	width:100%;
	p, .sort-by{
		line-height: 30px;
		font-size: 15px;
	    padding: 5px;
		margin:0;
		float:left;
		color: #222;
		font-weight:400;
		text-transform: capitalize;
	}
	.products-sort-order .select-title{
		border:$main_border;
		padding: 2px 15px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		line-height: 24px;
		font-size: 13px;
		margin:0;
		color: #404040;
		text-align:left;
		box-shadow:none;
		i {
			float:right;
		}
	}
	.products-sort-order .dropdown-menu{
		background: #fff;
		border: 1px solid #e1e1e1;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		right: 15px;
		left: auto;
		text-align:right;
		box-shadow: none;
		max-width:100%;
		.select-list{
			line-height: 24px;
			font-size: 14px;
			padding: 3px 15px;
			&:hover{
				background: $main;
			}
		}
	}
}


.text-muted {
	margin-bottom:20px;
}
/* end category page */

/* product page */

.h1.namne_details,.product_name_h1 {
    font-size: 34px;
    line-height: 36px;
    color: #333333;
	text-transform:capitalize;
	font-weight:900;
    margin:0 0 15px 0;
}
.product-prices {
	margin:0;
	.price,.current-price span:first-child{
	    font-weight: 700;
		font-size: 24px;
		line-height:30px;
		color: #ea3a3c;
	}
}
.product-discount {
    display:inline-block;
}
.product-discount .regular-price {
	font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #999;
}
.has-discount.product-price, .has-discount p {
	font-weight: 700;
    font-size: 20px;
    display: inline-block;
    color: #ea3a3c;
}
.has-discount .discount {
	font-weight: 700;
    font-size: 12px;
    line-height: 35px;
	height: 30px;
    color: white;
    padding:0 10px;
    background: #e54a38;
	vertical-align: 5px;
	border-radius:5px;
}
.product-price{
	font-size: 20px;
    font-weight: 700;
    color: #ea3a3c !important;
}
.product-line-grid-body>.product-line-info>.label{
	line-height: 1.4rem;
    display: block;
    overflow: hidden;
    color: #666666;
    font-size: 16px;
    margin-bottom: 12px;
    text-transform: capitalize;
    font-weight: 600;
	&:hover{
		color: $main;
	}
}
#blockcart-modal .cart-content .cart-content-btn .btn{
	@media (max-width: 768px) {
		font-size: 13px;
	}
}
.quickview .modal-footer{
	position: relative;
	#wishlist_button{
		position: absolute;
		right: 20px;
		top: 50%;
	}
}
.product-information {
    color: #666666;
    font-size: 14px;
    font-weight: normal;
	line-height: 25px;
	span {
		font-size:13px;
	}
	.product-desc p{
		font-size: 13px;
		color: #666666;
		margin: 0  0 15px 0;
		span {
			font-size: 13px !important;
			font-family: 'Open Sans', sans-serif !important;
			color: #666666;
		}
		
	}
	
}
.bootstrap-touchspin ,.product-variants>.product-variants-item select{
   box-shadow: none;
}
.product-variants>.product-variants-item {
    margin: 0 0 1.25rem 0;
}
.product-quantity {
	display:block;
	.qty {
		margin-right: 10px;
	}
	.add {
		@media (max-width: 360px) {
			clear:both;
			margin-top:10px;
		}
	}
}
.product-actions .add-to-cart {
	position: relative;
    padding: 0 20px ;
    color: $main;
    line-height:46px;
	height:44px;
    font-size: 18px;
    font-weight: 600;
    border: 0;
	background: transparent;
	font-size: 13px;
    border: 1px solid $main;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
	box-shadow: none;
	text-transform: uppercase;
	&:hover {
		background: $main;
		color: #fff;
	}
	i {
		display: none;
	}
}
.reference {
	font-size:13px;
	color: #999999;
	margin:0;
}
#product #block-reassurance {
	box-shadow: none;
	margin-top:20px;
	span {
	    font-weight: 400;
		font-size: 13px;
	}
	li .block-reassurance-item {
		padding: 15px 0;
	}
}
#product #content {
	overflow:hidden;
	max-width:470px;
}
.product-cover img {
    box-shadow:none; 
	border:none;
}
li.product-flag {
	background: $main;
    color: white;
    font-size: 12px;
    padding: 0;
    min-width: 60px;
    text-align: center;
    display: block;
    z-index: 1;
    line-height: 30px;
    border-radius: 20px;
    font-weight: 500;
    height: 30px;
	margin:0;
}
.product-additional-info{
	#wishlist_button{
		&:hover{
			color: $main;
		}
	}
}
.social-sharing {
    margin: 0;
    border-top: 1px solid #e5e5e5;
    padding-top: 30px;
	span {
		display:inline-block;
		margin-right:10px;
	}
}
.social-sharing li {
    box-shadow:none;
    height:auto;
    width: auto;
    border-radius:0;
    display: inline-block;
    background-color: transparent;
	line-height:1;
	margin:0;
	&:hover {
		&.facebook a {
			background:#435f9f;
			border-color:#435f9f;
		}
		&.twitter a {
			background:#00aaf0;
			border-color:#00aaf0;
		}
		&.googleplus a{
			background:#e04b34;
			border-color:#e04b34;
		}
		&.pinterest a {
			background:#ce1f21;
			border-color:#ce1f21;
		}
		a {
			color:$light;
		}
	}
	a {
		display: inline-block;
		line-height:36px;
		width:40px;
		height:40px;
		text-align:center;
		border: 2px solid #666666;
		color:#666666;
		margin-right:10px;
		white-space: normal;
		text-indent:0;
		overflow: hidden;
		font-size:0;
		padding:0;
		border-radius:100%;
		&:before {
			font-family: "Font Awesome\ 5 Brands";
			font-size:18px;
			display:inline-block;
		}
		
	}
	&.facebook a {
		&:before {
			content: "\f09a";
		}
	}
	&.twitter a {
		&:before {
			content: "\f099";
		}
	}
	&.googleplus a{
		&:before {
			content: "\f0d5";
		}	
	}
	&.pinterest a {
		&:before {
			content: "\f0d2";
		}
	}
}

.tabs {
    box-shadow: none;
	display:inline-block;
	width:100%;
	margin:30px 0;
	padding:0;
	position:relative;
	background:transparent;
	.nav-tabs {
		border: none;
		position: relative;
		display: block;
		border-bottom:1px solid #e5e5e5;
		.nav-item {
			position: relative;
			display: inline-block;
			margin:0;
			@media (max-width: 600px) {
				width:100%;
			}
			&:first-child{
				a{
					padding-left: 0;
				}
			}
			.nav-link {
				background:transparent;
				border:0;
				text-transform: uppercase;
				line-height:25px;
				font-weight: 400;
				color: #333; 
				padding:10px 35px;
				font-size: 24px;
				@include pos-transition(300ms);
				span {
					position:relative;
					z-index:1;
				}
				&:hover,&.active {
					font-weight: 700;
					@include pos-transition(300ms);
					
				}
				
			}
		}
	}
}
.tab-content {
    overflow: hidden;
    padding:40px 0;
    background:$light;
    font-size: 14px;
	background:$light;
	line-height: 25px;
}
.product-description p {
	font-size: 13px;
    color: #666666;
	span {
		font-size: 13px !important;
		font-family: 'Open Sans', sans-serif !important;
	}
}
#tab-content {
	font-size:12px;
	label {
		font-size:12px;
	}
}
#product-modal .modal-content .modal-body .product-images img {
    max-width: none;
	border: transparent 3px solid;
	@include pos-transition(300ms);
	&:hover {
		@include pos-transition(300ms);
	}
}
 #main .images-container .js-qv-mask, #main .images-container .js-qv-mask.scroll,.quickview .images-container .js-qv-mask  {
	max-width: 80%;
	margin:auto;
	overflow: visible;
	margin-bottom: 50px;
}
.images-container {
	.product-images {
		.owl-item {
			padding:0 5px;
			
		}
		.owl-nav{
			position:static;
			display:block;
			> div {
				&.owl-prev {
					left:-15px;
				}
				&.owl-next {
					right:-15px;
				}
			}
		}
	}
}	
.thumb-container {
	img {
		width:100%;
		height:auto;
		cursor:pointer;
		border:1px solid #d9d9d9;
		margin:0;
		@include pos-transition(300ms);
		&.selected,&:hover {
			border-color: $main;
			@include pos-transition(300ms);
		}
	}
}
.modal-content {
	.thumb-container {
		margin-bottom:10px;
	}
}
.product-cover .layer {
    background: transparent;
	@include pos-transition(300ms);
}
.product-cover .layer .zoom-in {
    color: $main;
	font-size: 4.25rem;
}
.product-accessories ,.categoryproducts{
	margin-bottom:50px;
}
.scroll-box-arrows.scroll {
    display: none;
}
.img-thumbnail {
    margin-bottom: 20px;
}
#product-modal .modal-content .modal-body .product-images img {
    max-width: 90px;
	margin:0;
}

#product-modal .modal-content .modal-body .image-caption {
    width: 100%;
}
#product-modal .modal-content .modal-body .mask {
    max-height: 398px;
}
#product-modal .modal-content .modal-body {
	margin:0;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
@media (min-width:992px) {
	#product-modal .modal-content .modal-body .product-images {
		display:inline-block;
	}
	.product-images > li.thumb-container {
		float: left;
		clear: both;
		display:inline-block;
	}
}
@media (min-width: 544px) {
	.modal-dialog {
		max-width: 700px;
	}
}
//@media (min-width: 1600px) {
//	.modal-dialog{
//		margin: 200px auto;
//	}
//}
.quickview .social-sharing {
  margin:0;
  text-align:left;
  border-top: none;
}
.quickview #wishlist_button{
	margin-bottom: 30px;
}
.product-discounts {
	margin:0;
}
.quickview  #product_comments_block_extra .comments_advices {
    display: none;
}
/* end product page */

/* ===== end edit theme ======== */
/* end custom */